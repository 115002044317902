import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarTitle } from 'vuetify/lib/components/VAppBar';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VAppBar,{attrs:{"app":"","clipped-left":"","elevation":"0","color":"topbar","height":"50%"}},[_c(VBtn,{attrs:{"icon":"","color":"topbarText","to":{ path: '/' }}},[_c(VIcon,[_vm._v("apps")])],1),_c(VAppBarTitle,{staticClass:"topbarText--text text-no-wrap",attrs:{"id":"welcome"}},[_vm._v(" KTL "),_c('span',{staticClass:"mx-2"},[_vm._v("|")]),_vm._v(" Project Management ")]),_c(VSpacer),_c('div',{staticClass:"d-flex flex-columns"},[_c(VTabs,{staticClass:"mx-2 align-self-center",attrs:{"background-color":"topbar","align-with-title":""},model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},_vm._l((_vm.links),function(item,i){return _c(VTab,{directives:[{name:"show",rawName:"v-show",value:(!item.disabled),expression:"!item.disabled"}],key:i,staticClass:"text-capitalize white--text",attrs:{"active-class":"green--text","to":item.to}},[_c(VIcon,{staticClass:"mx-2",attrs:{"color":_vm.tabs === item.to ? 'green' : 'white'}},[_vm._v(" "+_vm._s(item.icon)+" ")]),_vm._v(" "+_vm._s(item.title)+" ")],1)}),1)],1),(_vm.user)?_c(VMenu,{attrs:{"offset-y":"","transition":"slide-x-reverse-transition","left":"","nudge-bottom":"14","min-width":"230","content-class":"user-profile-menu-content"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VAvatar,_vm._g(_vm._b({attrs:{"size":"42","color":"white"}},'v-avatar',attrs,false),on),[_c(VAvatar,{attrs:{"size":"40"}},[_c(VImg,{attrs:{"src":_vm.profilePic,"lazy-src":require('@/assets/blank-profile.png')},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c(VRow,{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c(VProgressCircular,{attrs:{"size":25,"width":5,"indeterminate":"","color":"primary lighten-5"}})],1)]},proxy:true}],null,true)})],1)],1)]}}],null,false,3135908618)},[_c(VList,[_c(VListItemGroup,{attrs:{"color":"primary"}},[_c(VListItem,{attrs:{"link":""},on:{"click":function($event){return _vm.$router.replace({ path: '/dashboard/profile/profileSettings' })}}},[_c(VListItemContent,[_c(VListItemTitle,{staticClass:"text-h6 text-capitalize"},[_vm._v(" "+_vm._s(((_vm.user.first_name) + " " + (_vm.user.last_name)))+" ")]),_c(VListItemSubtitle,{staticClass:"pb-2"},[_vm._v(" "+_vm._s(_vm.user.email)+" ")])],1)],1),_c(VDivider),_c(VListItem,{attrs:{"link":""},on:{"click":function($event){return _vm.logout()}}},[_c(VListItemIcon,[_c(VIcon,{attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiLogoutVariant))])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" Logout ")])],1)],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }