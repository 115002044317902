<template>
  <v-dialog v-model="editDialog" persistent :width="$vuetify.breakpoint.mdAndUp ? '70%' : '100%'"
    :fullscreen="$vuetify.breakpoint.mobile">
    <v-card :class="`px-${padding}`" v-if="displayIssue">
      <v-card-title class="d-flex ml-4 pb-0">
        <v-chip label small :color="`${issueTypeColor(selectedItem.issueType)} lighten-5`" :class="`${issueTypeColor(
          selectedItem.issueType
        )}--text text--darken-4 text-capitlize mr-4`">{{ selectedItem.issueType }}</v-chip>
        {{ selectedItem.issueCode }}

        <v-spacer></v-spacer>

        <v-btn id="closeIssueBtn" color="primary" depressed small v-if="selectedItem.status !== 'DONE'"
          @click="updateStatus('DONE')">
          <v-icon class="mr-1">mdi-lock-check</v-icon>
          close issue
        </v-btn>

        <!-- <v-btn
          color="primary"
          depressed
          small
          v-if="selectedItem.status === 'DONE'"
          @click="updateStatus('TODO')"
        >
          <v-icon class="mr-1">mdi-lock-open-variant</v-icon>
          reopen issue
        </v-btn> -->

        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-on="on" :attrs="attrs" icon>
              <v-icon size="25">fa-solid fa-ellipsis</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item dense>
              <v-list-item-title class="pa-1">
                <v-icon color="red">mdi-flag-triangle</v-icon>
                Flag
              </v-list-item-title>
            </v-list-item>
            <!-- <v-list-item
            dense
            v-if="selectedItem.status !== 'Closed'"
            @click="updateStatus('Closed')"
          >
            <v-list-item-title class="pa-1"> Close Issue </v-list-item-title>
          </v-list-item>
          <v-list-item
            dense
            v-if="selectedItem.status === 'Closed'"
            @click="updateStatus('Closed')"
          >
            <v-list-item-title class="pa-1"> Reopen issue </v-list-item-title>
          </v-list-item> -->
          </v-list>
        </v-menu>

        <v-btn icon @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pb-16">
        <v-form>
          <v-row>
            <v-col cols="12" md="7">
              <v-card-title class="text-h4 font-weight-black py-0">
                <v-tooltip top :disabled="selectedItem.status === 'Closed'">
                  <template v-slot:activator="{ on, attrs }">
                    <v-hover v-slot="{ hover }" v-if="selectedItem.title">
                      <span v-bind="attrs" v-on="on" :text="hover ? false : true" :ripple="hover"
                        v-if="!editTitle && selectedItem.title" class="text-capitalize"
                        style="word-break: break-word; cursor: pointer" @click="
                          selectedItem.status !== 'Closed'
                            ? editing()
                            : editTitle
                        " v-html="selectedItem.title" />
                    </v-hover>
                  </template>

                  <span>Click to edit</span>
                </v-tooltip>

                <div v-if="editTitle" class="editor">
                  <div>
                    <v-text-field ref="titleInput" v-model="selectedItem.title" label="Title" dense filled />
                  </div>

                  <div class="d-flex mt-3">
                    <v-btn small depressed color="primary" @click="(editTitle = false), selfUpdate()">
                      save
                    </v-btn>

                    <v-btn depressed color="error" plain small @click="editTitle = false">
                      cancel
                    </v-btn>
                  </div>
                </div>
              </v-card-title>

              <div class="ml-4 mb-2">
                <v-chip id="attachment" @click="$refs.uploader.$refs.input.click()" label>
                  <v-icon small>fa fa-paperclip</v-icon>
                  Attach
                </v-chip>

                <v-chip id="childIssue" class="ma-2" label @click="addChildIssue = true" ripple>
                  <v-icon class="mx-2">account_tree</v-icon>
                  Add a Child Issue
                </v-chip>
              </div>

              <!-- description -->
              <div class="ml-4">
                <span class="text-h6 font-weight-black">Description</span>

                <br />

                <v-tooltip top :disabled="selectedItem.status === 'Closed'">
                  <template v-slot:activator="{ on, attrs }">
                    <v-hover v-slot="{ hover }" v-if="selectedItem.description">
                      <span v-bind="attrs" v-on="on" :text="hover ? false : true" :ripple="hover" v-if="!editDescription"
                        class="text-capitalize" style="word-break: break-word; cursor: pointer" @click="
                          selectedItem.status !== 'Closed'
                            ? (editDescription = !editDescription)
                            : editDescription
                        " @click.prevent="editingDescription()" v-html="selectedItem.description" />
                    </v-hover>

                    <v-hover v-slot="{ hover }" v-else>
                      <v-chip v-bind="attrs" v-on="on" :text="hover ? false : true" :ripple="hover"
                        v-if="!editDescription" @click="editDescription = !editDescription"
                        @click.prevent="editingDescription()" label style="width: 440px" class="mt-3"
                        color="grey lighten-3">
                        Add a description...
                      </v-chip>
                    </v-hover>
                  </template>

                  <span>Click to edit</span>
                </v-tooltip>

                <div v-if="editDescription" class="editor">
                  <Editor class="editor" v-model="description" :editorToolbar="customToolbar"
                    :editorOptions="editorSettings" @image-added="handleImageAdded" ref="editingDescription" />
                  <div class="d-flex mt-3" v-if="editDescription">
                    <v-btn small depressed color="primary" @click="(editDescription = false), selfUpdate()">
                      save
                    </v-btn>

                    <v-btn depressed color="error" plain small @click="editDescription = false">
                      cancel
                    </v-btn>
                  </div>
                </div>
              </div>

              <div id="editorInput" style="display: none">
                <div class="ql-editor" v-html="description" id="quizOutput"></div>
              </div>

              <!-- child issues -->
              <div class="ml-4" v-if="addChildIssue || selectedItem.childIssues.length !== 0">
                <ChildIssues :issue="selectedItem" :displayChildForm="addChildIssue" @closeChildIssue="closeChildIssue" />
              </div>

              <!-- attachments -->
              <div class="mt-2 ml-4" v-if="selectedItem.projectAttachment">
                <span>Attachments</span>

                <v-alert type="error" dense text icon="mdi-cloud-alert" border="left" v-if="attachmentError">
                  Each attachment should be less than 2MB and not more than 5
                  attachments
                </v-alert>

                <AttachmentView class="pl-0" :attachments="projectAttachment" />
              </div>

              <v-file-input accept="application/pdf,.jpeg,.png,.jpg,GIF" ref="uploader" hide-input multiple
                prepend-icon="" @change="uploadFile" />

              <!-- comments and history -->
              <v-card-text class="pt-0"
              id="comment_history"
              >
                <span class="text-h6 font-weight-black">Activity</span>
                <br />

                <span>Show:</span>

                <v-btn-toggle color="primary" dense v-model="term" tile group>
                  <v-btn small v-for="(item, i) in filters" :key="i" :value="item.toLowerCase()" @click="term = item">
                    <span>{{ item | capitalize }}</span>
                  </v-btn>
                </v-btn-toggle>

                <Comments id="comment" :term="term" :issue="selectedItem" />
              </v-card-text>
            </v-col>

            <v-col cols="12" md="5">
              <v-card-title>
                <v-row>
                  <v-col cols="5" class="pl-0 ml-n1">
                    <v-chip label :color="`${issueStatusColor(
                      selectedItem.status
                    )} lighten-5`" :class="`${issueStatusColor(
  selectedItem.status
)}--text text--darken-4 text-capitlize`">
                      {{ selectedItem.status }}
                    </v-chip>
                  </v-col>

                  <v-spacer></v-spacer>

                  <v-col cols="7" class="d-flex justify-end align-end">
                    <span style="text-align: right" class="font-weight-bold text-h4">
                      {{
                        checkNumber(
                          Math.ceil(
                            (new Date(selectedItem.dueDate).getTime() -
                              new Date(Date.now()).getTime()) /
                            (1000 * 3600 * 24)
                          )
                        )
                      }}
                    </span>

                    <span class="text-overline mb-n1">
                      &nbsp;more day{{
                        `${checkNumber(
                          Math.ceil(
                            (new Date(selectedItem.dueDate).getTime() -
                              new Date(Date.now()).getTime()) /
                            (1000 * 3600 * 24)
                          )
                        ) > 1
                          ? "s"
                          : ""
                        }`
                      }}
                    </span>
                  </v-col>
                </v-row>
              </v-card-title>

              <v-card outlined>
                <v-card-text>
                  <v-list dense>
                    <v-list-item-group>
                      <!-- project -->
                      <v-list-item>
                        <v-list-item-content class="text-capitalize">
                          Project
                        </v-list-item-content>

                        <v-list-item-content>
                          <span class="ml-4">
                            {{ selectedItem.projectCode }}
                          </span>
                        </v-list-item-content>
                      </v-list-item>

                      <!-- Project Product -->
                      <v-list-item>
                        <v-list-item-content>
                          Project Product
                        </v-list-item-content>

                        <v-list-item-content>
                          <span class="ml-4">
                            {{ selectedItem.productCode }}
                          </span>
                        </v-list-item-content>
                      </v-list-item>

                      <!-- Project Module -->
                      <v-list-item>
                        <v-list-item-content>
                          Project Module
                        </v-list-item-content>

                        <v-list-item-content>
                          <span class="ml-4 text-uppercase">
                            {{
                              selectedItem.productModules
                              ? selectedItem.productModules.name
                              : ""
                            }}
                          </span>
                        </v-list-item-content>
                      </v-list-item>

                      <!-- assignee -->
                      <v-list-item>
                        <v-list-item-content>Assignee</v-list-item-content>

                        <v-list-item-content>
                          <div class="d-flex ml-3" v-if="agent">
                            <v-menu v-model="menu" :close-on-content-click="false" :nudge-width="90" open-on-hover left
                              offset-x>
                              <template v-slot:activator="{ on, attrs }">
                                <v-avatar v-on="on" v-bind="attrs" class="white--text mr-3" :size="24"
                                  :color="userColor(agent.name || '')">
                                  {{ agent.code }}
                                </v-avatar>
                              </template>
                              <v-card class="mx-auto">
                                <v-list>
                                  <v-list-item>
                                    <v-avatar class="white--text mr-3" :color="userColor(agent.name || '')">
                                      {{ agent.code }}
                                    </v-avatar>

                                    <v-list-item-content>
                                      <v-list-item-title>
                                        {{ agent.name }}
                                      </v-list-item-title>
                                      <v-list-item-subtitle>
                                        {{ agent.eMail }}
                                      </v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                      <v-icon color="primary">verified</v-icon>
                                    </v-list-item-action>
                                  </v-list-item>
                                </v-list>
                                <v-divider></v-divider>
                                <v-list>
                                  <v-list-item>
                                    <v-list-item-title>Phone:</v-list-item-title>
                                    <v-list-item-subtitle>
                                      {{ agent.phoneNo }}
                                    </v-list-item-subtitle>
                                  </v-list-item>
                                </v-list>
                              </v-card>
                            </v-menu>

                            <v-list-item-title>
                              {{ agent.name.split(" ")[0] }}
                            </v-list-item-title>
                          </div>

                          <div v-else class="ml-3">
                            <v-avatar color="grey" class="mr-2 white--text" :size="24" />
                            _ _ _
                          </div>
                        </v-list-item-content>
                      </v-list-item>

                      <!-- reporter -->
                      <v-list-item>
                        <v-list-item-content>Reporter</v-list-item-content>

                        <v-list-item-content v-if="selectedItem.reporter">
                          <div class="d-flex ml-3" v-if="selectedItem.reporter">
                            <v-avatar v-if="selectedItem.reporter" class="mr-2 white--text" :size="24"
                              :color="userColor(selectedItem.reporter)">
                              <v-img v-if="selectedItem.reporter" :src="getReporterAvatar(selectedItem.reporter)"
                                contain />
                            </v-avatar>

                            <v-avatar v-else color="grey" class="mr-2 white--text" :size="24" />

                            <span class="text-capitalize" v-if="selectedItem.reporter">
                              {{ getReporterName(selectedItem.reporter) }}
                            </span>
                          </div>
                        </v-list-item-content>
                      </v-list-item>

                      <!-- priority -->
                      <v-list-item>
                        <v-list-item-content class="text-capitalize">
                          priority
                        </v-list-item-content>

                        <v-list-item-content v-if="selectedItem.priorities">
                          <v-tooltip top v-if="!editPriority" :disabled="selectedItem.status === 'Closed'">
                            <template v-slot:activator="{ on, attrs }">
                              <v-hover v-slot="{ hover }" v-if="!editPriority">
                                <v-btn v-on="on" v-bind="attrs" :text="hover ? false : true" :ripple="hover"
                                  v-if="!editPriority" @click="
                                    selectedItem.status !== 'Closed'
                                      ? (editPriority = !editPriority)
                                      : editPriority
                                  " class="black--text text-capitalize ripple" elevation="0"
                                  :loading="selfLoading.priorities">
                                  <div class="d-flex">
                                    <v-icon class="mr-3" :color="
                                      issuePriorityColor(
                                        selectedItem.priorities
                                      )
                                    ">
                                      low_priority
                                    </v-icon>
                                    <div>{{ selectedItem.priorities }}</div>
                                  </div>
                                </v-btn>
                              </v-hover>
                            </template>

                            <span>CLick to edit</span>
                          </v-tooltip>

                          <v-autocomplete v-if="editPriority" v-model="selectedItem.priorities" :items="priorities"
                            :item-text="(item) => item.description" :item-value="(item) => item.code"
                            v-on:change="selfUpdate()" label="Priority" outlined :filled="
                              selectedItem.priorities.length === 0
                                ? true
                                : false
                            " dense />
                        </v-list-item-content>
                      </v-list-item>

                      <!-- due date -->
                      <v-list-item>
                        <v-list-item-content class="text-capitalize">
                          due date
                        </v-list-item-content>

                        <v-list-item-content>
                          <div class="d-flex align-self-left ml-4">
                            <v-icon class="mr-3"> event </v-icon>
                            <div>{{ dateFormat(selectedItem.dueDate) }}</div>
                          </div>
                        </v-list-item-content>
                      </v-list-item>

                      <!-- updated at -->
                      <v-list-item>
                        <v-list-item-content class="text-capitalize">
                          updated at
                        </v-list-item-content>

                        <v-list-item-content>
                          <span class="ml-4">
                            {{ dateFormat(selectedItem.updated_at) }}
                          </span>
                        </v-list-item-content>
                      </v-list-item>

                      <!-- created at -->
                      <v-list-item>
                        <v-list-item-content class="text-capitalize">
                          created at
                        </v-list-item-content>

                        <v-list-item-content>
                          <span class="ml-4">
                            {{ dateFormat(selectedItem.created_at) }}
                          </span>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <v-card v-else>
      <v-row>
        <v-col cols="12" md="8" lg="8" sm="12">
          <v-card>
            <v-skeleton-loader
              type="card-heading, list-item-two-line, list-item-two-line, list-item-three-line"></v-skeleton-loader>
          </v-card>
        </v-col>
        <v-col cols="12" md="4" lg="4" sm="12">
          <v-card>
            <v-skeleton-loader
              type="card-heading, list-item-two-line, list-item-two-line, list-item-three-line"></v-skeleton-loader>
          </v-card>
        </v-col>
      </v-row>
    </v-card>

    <DialogSpinner />
    <v-tour name="modalTour" :steps="steps" :options="myOptions"></v-tour>
  </v-dialog>
</template>
<script>
import issueMixin from "../issueMixin";
import EditorMixin from "../EditorMixin";
import Comments from "./Comments.vue";
import { Quill, VueEditor as Editor } from "vue2-editor";
import { ImageDrop } from "quill-image-drop-module";
import ChildIssues from "./ChildIssues";
import AttachmentView from "./AttachmentsView.vue";
import helpers from "@/packages/dashboard/utils/helpers/helper.js";
import DialogSpinner from "@/packages/dashboard/plugins/loader/views/DialogSpinner.vue";
import colors from "../styles/colors";

window.Quill = Quill;
const ImageResize = require("quill-image-resize-module").default;
Quill.register("modules/imageResize", ImageResize);
Quill.register("modules/imageDrop", ImageDrop);

export default {
  name: "EditIssueView",
  mixins: [issueMixin, EditorMixin],
  components: { Comments, Editor, ChildIssues, AttachmentView, DialogSpinner },
  data() {
    return {
      menu: false,
      term: "comments",
      filters: ["Comments", "History"],
      editAgent: false,
      editPriority: false,
      dateMenu: false,
      editDueDate: false,
      editTitle: false,
      editDescription: false,
      sortDesc: false,
      addChildIssue: false,
      editDialog: true,
      url: process.env.VUE_APP_TEMP_URL,
      attachmentError: false,
      editorSettings: {
        modules: {
          imageDrop: true,
          imageResize: {},
        },
      },
      description: "",
      steps: [
        {
          target: "#closeIssueBtn",
          header: {
            title: "Close Issue",
          },
          content:
            "Click the 'Close Issue' button to close the issue when resolved!.",
        },
        {
          target: "#childIssue",
          heading: { title: "Attach child Issues" },
          content: "You can attach child issue here!",
        },
        {
          target: "#attachment",
          header: {
            title: "Attachment",
          },
          content: " Use this button to add attachment.",
        },
        {
          target: "#comment_history",
          header: {
            title: "Comments & History",
          },
          content: `Comment: Use the comment tab to see comments on the issue.\n
                    \nHistory: Use the history tab to see issue's history.
          `,
          params: {
            placement: "top",
          },

        },
      ],
      myOptions: {
        labels: {
          buttonSkip: "Exit",
          buttonPrevious: "Back",
          buttonNext: "Next",
          buttonStop: "Finish",
        },
      },
    };
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Ticket/getIssue", v.decodeRoute(to.params.id));
    });
  },
  mounted() {
    // const itemMounted = document.querySelector("#closeIssueBtn");
    setTimeout(function(){
      this.$tours["modalTour"].start();
     },500);
    Event.$on("clearEditor", () => {
      this.description = "";
    });
  },
  props: { padding: Number },
  computed: {
    priorities: function () {
      return this.$store.getters["Setup/setupGetters"]("issuePriorities");
    },
    agent: function () {
      if (Object.keys(this.selectedItem).length !== 0) {
        return this.selectedItem.assignee
          ? this.agents
            .filter((item) => item.code === this.selectedItem.assignee)
            .shift()
          : "";
      }
      return "";
    },
    selectedItem: function () {
      return this.$store.getters["Ticket/issueGetters"]("selectedItem");
    },
    displayIssue() {
      return (
        this.selectedItem.issueCode === this.decodeRoute(this.$route.params.id)
      );
    },
    projectAttachment() {
      return this.selectedItem ? this.selectedItem.projectAttachment : null;
    },
  },
  methods: {
    closeChildIssue(val) {
      this.addChildIssue = val;
    },
    closeDialog: function () {
      this.$router.replace({
        name: "Main",
      });
    },
    getReporterName(val) {
      if (val) {
        const data = this.reporters.filter((d) => d.code === val);
        if (data.length !== 0) {
          return data[0].name;
        } else {
          return "";
        }
      }
    },
    getReporterAvatar(val) {
      if (val) {
        const data = this.reporters.filter((d) => d.code === val);
        if (data.length !== 0) {
          return data[0].avatar
            ? `data:image/png;base64,${data[0].avatar}`
            : require("@/assets/60111.jpg");
        } else {
          return "";
        }
      }
    },
    uploadFile: async function (value) {
      if (value) {
        if (value.length !== 0) {
          value.forEach((item) => {
            if (item.size > 2000000 || value.length > 5) {
              this.attachmentError = true;
            } else {
              this.attachmentError = false;
              var attachments = new Array();
              attachments = value;
              let data = {
                issueCode: this.selectedItem.issueCode,
              };
              const formData = helpers.prepareFormData(data);
              attachments.forEach((file) => {
                console.log(file);
                formData.append("attachments[]", file);
              });
              this.$store.dispatch("Ticket/addAttachment", {
                data: formData,
                code: data.issueCode,
              });
            }
          });
        }
      }
    },
    editing() {
      this.editTitle = !this.editTitle;
      this.$nextTick(() => {
        this.$refs.titleInput.focus();
      });
    },
    checkNumber(num) {
      if (Math.sign(num) === 1) return num;
      return 0;
    },
    editingDescription() {
      this.$nextTick(() => {
        this.$refs.editingDescription.quill.focus();
      });
    },
    issueTypeColor(type) {
      return colors.issueType()[type.toLowerCase()];
    },
    // eslint-disable-next-line no-unused-vars
    handleImageAdded(file, Editor, cursorLocation, resetUploader) { },
  },
};
</script>
<style scoped>
.editor {
  width: 100% !important;
  height: 50%;
}
</style>
