<template>
  <v-dialog
    v-model="profileDialog"
    persistent
    max-width="40%"
    :fullscreen="$vuetify.breakpoint.mobile"
  >
    <v-card class="px-6 d-flex flex-column justify-center">
      <v-card-title>
        <v-spacer></v-spacer>
        <v-btn text color="secondary" @click="skip">
          skip
          <v-icon small>mdi-chevron-right</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-subtitle class="d-flex justify-center">
        <v-avatar size="100" color="grey">
          <v-img
            v-model="formData.avatar"
            v-if="profile ? profile.avatar : profile"
            :src="`data:image/png;base64,${profile.avatar}`"
          />
          <v-img
            :src="base64Pic ? base64Pic : require('@/assets/60111.jpg')"
          ></v-img>
        </v-avatar>
      </v-card-subtitle>

      <v-card-subtitle class="d-flex justify-center mb-5">
        <span class="text-h4 mb-n2">
          {{ project ? project.description : "" }}
        </span>
      </v-card-subtitle>

      <v-card-text>
        <v-form ref="resetPasswordForm" @submit.prevent="reset">
          <v-row>
            <v-col cols="12" class="mt-n6">
              <v-file-input
                @change="uploadProfilePicture($event)"
                filled
                single-line
                dense
                :rules="rules.avatar"
                append-icon="mdi-paperclip"
                accept=".jpeg,.png,.jpg,GIF"
                placeholder="Upload Profile"
                prepend-icon=""
                ref="uploader"
                @keyup.enter="reset()"
              />
            </v-col>

            <v-col cols="12" class="mt-n6">
              <v-text-field
                v-model="formData.password"
                filled
                dense
                single-line
                @copy.prevent
                label="Password"
                :type="showPassword ? 'text' : 'password'"
                :rules="rules.password"
                autocomplete="false"
                @keyup.enter="reset()"
              >
                <template v-slot:append>
                  <v-btn @click="showPassword = !showPassword" icon>
                    <v-icon small>
                      {{ showPassword ? "visibility" : "visibility_off" }}
                    </v-icon>
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>

            <v-col cols="12" class="mt-n6">
              <v-text-field
                v-model="formData.confirmPassword"
                filled
                dense
                single-line
                @copy.prevent
                label="Confirm Password"
                :type="showConfirmPassword ? 'text' : 'password'"
                :rules="rules.confirmPassword"
                autocomplete="false"
                @keyup.enter="reset()"
              >
                <template v-slot:append>
                  <v-btn
                    @click="showConfirmPassword = !showConfirmPassword"
                    icon
                  >
                    <v-icon small>
                      {{
                        showConfirmPassword ? "visibility" : "visibility_off"
                      }}
                    </v-icon>
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" v-if="!submitting" @click="reset">submit</v-btn>
        <v-btn color="grey" v-else disabled>
          <i class="fa fa-refresh fa-spin loading-button-spinner"></i>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { helper } from "@/packages/dashboard/utils";
export default {
  name: "UpdateProfile",
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      submitting: false,
      formData: {
        password: "",
        confirmPassword: "",
        avatar: "",
        token: "",
      },
      file: "",
      showPassword: false,
      showConfirmPassword: false,
      base64Pic: "",
    };
  },
  computed: {
    profile() {
      return null;
    },
    profileDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("update:dialog", value);
      },
    },
    rules() {
      return {
        required: [(v) => !!v || "Field is required"],
        password: [
          (v) => !!v || "Password is required",
          (v) =>
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!#~^()`.,;:{}_/%*?&])[A-Za-z\d@$!#~^()`.,;:{}_/%*?&]{8,16}$/.test(
              v
            ) ||
            "Password should contain: Minimum 8 and maximum 16 characters, at least one uppercase letter, one lowercase letter, one number and one special character",
        ],
        confirmPassword: [
          (v) => !!v || "Password is required",
          (v) => v === this.formData.password || "Passwords do not match",
        ],
        phoneNo: [
          (v) => !!v || "Field is required",
          (v) =>
            !v ||
            /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(v) ||
            "Invalid phone number format",
        ],
        avatar: [
          (v) =>
            this.formData.avatar && !!v
              ? v.size < 2000000 || "Attachment should be less than 2MB"
              : true,
        ],
      };
    },
    project() {
      const projects = this.$store.getters["Setup/setupGetters"]("projects");
      return projects
        .filter((item) => {
          return item.code === this.$route.params.company;
        })
        .shift();
    },
  },
  methods: {
    reset: function () {
      this.submitting = true;
      if (!this.$refs.resetPasswordForm.validate()) {
        this.submitting = false;
      } else {
        this.formData.token = this.$route.params.token;
        this.$store.dispatch(
          "Auth/resetPassword",
          helper.prepareFormData(this.formData)
        );
        this.submitting = false;
      }
    },
    async uploadProfilePicture(event) {
      this.formData.avatar = event;
      const value = this.$refs.uploader.$refs.input.files[0];
      this.base64Pic = await helper.toBase64(value);
    },
    skip() {
      const token = { token: this.$route.params.token };
      this.$store.dispatch("Landing/skipChangeDetails", {
        ...token,
      });
    },
  },
};
</script>
