import call from "../dashboard/service/http";
import SetupConstants from "./SetupConstants";

export default {
  namespaced: true,
  state: {
    issueTypes: [],
    projects: [],
    projectIssues: [],
    issueStatus: [],
    issuePriorities: [],
    sprints: [],
    agents: [],
    reporters: [],
    clients: [],
    assignees: [],
    modules: [],
    projectProducts: [],
  },
  mutations: {
    MUTATE: (state, payload) => {
      state[payload.state] = payload.data;
    },
  },
  getters: {
    setupGetters: (state) => (val) => state[val],
  },
  actions: {
    getissueTypes: ({ commit }) => {
      call("get", SetupConstants.SETUPS("issueTypes"))
        .then((res) => {
          commit("MUTATE", { state: "issueTypes", data: res.data.data });
        })
        .catch((error) => {
          console.error(
            "Failed To Fetch Issue Types",
            error.response.data.message
          );
        });
    },
    getProjects: ({ commit }) => {
      call("get", SetupConstants.SETUPS("projectsApi"))
        .then((res) => {
          commit("MUTATE", { state: "projects", data: res.data.data });
        })
        .catch((error) => {
          console.error(
            "Failed To Fetch Projects",
            error.response.data.message
          );
        });
    },
    getStatuses: ({ commit }) => {
      call("get", SetupConstants.SETUPS("issueStatus"))
        .then((res) => {
          commit("MUTATE", { state: "issueStatus", data: res.data.data });
        })
        .catch((error) => {
          console.error(
            "Failed To Fetch Issue Status",
            error.response.data.message
          );
        });
    },
    getPriorities: ({ commit }) => {
      call("get", SetupConstants.SETUPS("issuePriorities"))
        .then((res) => {
          commit("MUTATE", { state: "issuePriorities", data: res.data.data });
        })
        .catch((error) => {
          console.error(
            "Failed To Fetch Issue Priorities",
            error.response.data.message
          );
        });
    },
    getprojectIssues: ({ commit }) => {
      call("get", SetupConstants.SETUPS("projectIssuesApi"))
        .then((res) => {
          commit("MUTATE", { state: "projectIssues", data: res.data.data });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
        });
    },
    getReporters: ({ commit }) => {
      call("get", SetupConstants.reporters)
        .then((res) => {
          commit("MUTATE", { state: "reporters", data: res.data.data });
        })
        .catch((error) => {
          console.error(
            "Failed To Fetch Reporters",
            error.response.data.message
          );
        });
    },
    getAssignees: ({ commit }) => {
      call("get", SetupConstants.assignees)
        .then((res) => {
          commit("MUTATE", { state: "assignees", data: res.data.data });
        })
        .catch((error) => {
          console.error(
            "Failed To Fetch Assignees",
            error.response.data.message
          );
        });
    },
    getprojectProductModules: ({ commit }) => {
      call("get", SetupConstants.SETUPS("projectProductModules"))
        .then((res) => {
          commit("MUTATE", { state: "modules", data: res.data.data });
        })
        .catch((error) => {
          console.error(
            "Failed To Fetch project product modules",
            error.response.data.message
          );
        });
    },
    getprojectProducts: ({ commit }) => {
      call("get", SetupConstants.SETUPS("projectProducts"))
        .then((res) => {
          commit("MUTATE", { state: "projectProducts", data: res.data.data });
        })
        .catch((error) => {
          console.error(
            "Failed To Fetch project products",
            error.response.data.message
          );
        });
    },
  },
};
