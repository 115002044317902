import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import colors from "vuetify/lib/util/colors";

Vue.component("font-awesome-icon", FontAwesomeIcon);
library.add(fas);

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#0D47A1",
        secondary: "#ff5733",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
        topbar: "#030F3A",
        topbarText: "#FFFFFF",
        drawerBackground: "#E5E7E9",
        loaderColor: "#1e8449",
        detailedViewBackground: "#f5f5f5",
        detailedViewAvtivePrimary: colors.teal.lighten4,
        detailedViewAvtiveSecondary: colors.teal.darken4,
      },
    },
  },
  icons: {
    iconfont: "md",
  },
});
