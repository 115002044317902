import call from "../../service/http";
import constants from "./landingconstants";
import AuthService from "../auth/AuthService";

export default {
  namespaced: true,
  state: {
    company: {},
  },
  mutations: {
    SET_COMPANY_LOGO: (state, payload) => {
      state.company = payload;
    },
  },
  getters: {
    company: (state) => state.company,
  },
  actions: {
    skipChangeDetails: ({ commit }, payload) => {
      commit("Dashboard/SET_LOADING", true, { root: true });
      call("post", constants.SKIP, payload)
        .then((res) => {
          if (res.data.data.user) {
            AuthService.login(res.data.data.token, res.data.data.user);
          }
          commit("Dashboard/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", error.response.data.message);
        });
    },
  },
};
