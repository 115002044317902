import { issueRoutes, issueStore } from "./modules/issues";
import ticketLinks from "./ticketLinks";

export default {
  install(Vue, options) {
    if (options.router) {
      options.router.addRoute(...issueRoutes);
    }

    if (options.store) {
      options.store.registerModule("Ticket", issueStore);
      options.store.dispatch("Dashboard/setLinks", ticketLinks);
    }
  },
};
