import _ from "lodash";
import dashboardConstants from "./dashboardConstants";
import { call } from "../../service";
export default {
  namespaced: true,
  state: {
    loading: false,
    state: { loading: false },
    miniDrawer: false,
    links: [
      {
        to: "/dashboard/main",
        order: 1,
        icon: "fa-light fa-house",
        title: "Home",
        disabled: false,
      },
    ],
    toolBarTopLinks: [],
    actions: [],
    menus: [],
    companies: [],
    dialogLoading: false,
  },
  mutations: {
    SET_LOADING: (state, payload) => {
      state.loading = payload;
    },

    SET_COMPANIES: (state, payload) => {
      state.companies = payload;
    },

    SET_SET_MENU: (state, payload) => {
      if (_.findIndex(state.menu, payload) === -1) {
        state.menus.push(payload);
      }
    },

    SET_LINKS: (state, payload) => {
      if (_.findIndex(state.links, payload) === -1) {
        state.links.push(payload);
      }
    },

    SET_TOP_BAR_LINKS: (state, payload) => {
      if (_.findIndex(state.links, payload) === -1) {
        state.links.push(payload);
      }
    },

    SET_TOOL_BAR_TOP_LINKS: (state, payload) => {
      state.toolBarTopLinks = payload;
    },

    SET_ACTIONS: (state, payload) => {
      if (_.findIndex(state.actions, payload) === -1) {
        state.actions.push(payload);
      }
    },

    SET_MINI_DRAWER: (state, payload) => {
      state.miniDrawer = payload;
    },
    SET_DIALOG_LOADING: (state, payload) => {
      state.dialogLoading = payload;
    },
  },

  getters: {
    loading: (state) => state.loading,
    actions: (state) => state.actions,
    menus: (state) => state.menus,
    companies: (state) => state.companies,
    links: (state) => {
      return _.orderBy(state.links, (link) => link.order);
    },
    topBarLinks: (state) => {
      return _.orderBy(state.topBarLinks, (link) => link.order);
    },
    toolBarTopLinks: (state) => {
      return _.orderBy(state.toolBarTopLinks, (link) => link.order);
    },
    miniDrawer: (state) => state.miniDrawer,
    dialogLoading: (state) => state.dialogLoading,
  },

  actions: {
    resetPassword: ({ commit }, data) => {
      commit("Dashboard/SET_LOADING", true, { root: true });
      call("post", dashboardConstants.password, data)
        .then(() => {
          Event.$emit("ApiSuccess", "Password Updated");
          commit("Dashboard/SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", err.response.data.message);
        });
    },

    getCompanies: ({ commit }) => {
      call("get", dashboardConstants.companies)
        .then((res) => {
          commit("SET_COMPANIES", res.data.data);
        })
        .catch((err) => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", err.response.data.message);
        });
    },

    setMiniDrawer: ({ commit }, data) => {
      commit("SET_MINI_DRAWER", data);
    },

    setToolBarLinks: ({ commit }, data) => {
      commit("SET_TOOL_BAR_TOP_LINKS", data);
    },

    setLinks: ({ commit }, data) => {
      commit("SET_LINKS", data);
    },
  },
};
