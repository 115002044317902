export default {
  methods: {
    extractImages(contentHtml) {
      let imageTag = document.createElement("p");
      let query = contentHtml.querySelectorAll("*");
      for (var i = 0; i < query.length; i++) {
        const img = query[i].getElementsByTagName("img")[0];
        if (img) {
          imageTag.appendChild(img);
        }
      }
      return imageTag;
    },
    async getbase64Content(htmlOutput) {
      if (!htmlOutput.innerHTML.split("<img")[1]) {
        return null;
      }
      var dataUrl = htmlOutput.innerHTML
        .split("<img")[1]
        .split('">')[0]
        .split("src=")[1];
      return dataUrl.split(",")[1];
    },
    htmlToString(contentHtml) {
      var children = contentHtml.querySelectorAll("*");
      for (var i = 0; i < children.length; i++) {
        if (children[i].textContent) children[i].textContent += " ";
        else children[i].innerText += " ";
      }
      return [contentHtml.textContent || contentHtml.innerText]
        .toString()
        .replace(/ +/g, " ");
    },
  },
};
