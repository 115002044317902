import constants from "./constants";
import { call } from "../dashboard/service";

export default {
  namespaced: true,
  state: {
    users: [],
  },
  mutations: {
    MUTATE: (state, payload) => {
      state[payload.state] = payload.data;
    },
  },
  getters: {
    userGetters: (state) => (val) => state[val],
  },
  actions: {
    getUsers({ commit }) {
      commit("Dashboard/SET_LOADING", true, { root: true });
      call("get", constants.GET_REPORTERS)
        .then((res) => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          commit("MUTATE", { state: "users", data: res.data.data });
        })
        .catch((error) => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", error.response.message);
        });
    },
    sendInvitation({ commit, dispatch }, data) {
      commit("Dashboard/SET_LOADING", true, { root: true });
      call("post", constants.GET_REPORTERS, data)
        .then((res) => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          Event.$emit("ApiSuccess", res.data.data);
          dispatch("getUsers");
        })
        .catch((error) => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", error.response.data.message);
        });
    },
  },
};
