import Landing from "./views/landing.vue";
import IssuesCard from "./components/LandingIssueCard";

export default [
  {
    path: "/",
    component: Landing,
    name: "Landing",
  },
  {
    path: "/auth/authenticate/:token/:company",
    component: IssuesCard,
    name: "CreateTicket",
  },
];
