import AuthLayout from "./views/AuthLayout";
import Verify from "./components/Verify";
import ForgotPassword from "./components/ForgotPassword";
import RequestCredentials from "./components/RequestCredentials";
import Landing from "../landing/views/landing.vue";
import SetPassword from "./components/SetPassword";

export default [
  {
    path: "/auth",
    name: "Auth",
    component: AuthLayout,
    children: [
      {
        path: "forgot-password",
        name: "forgotPassword",
        component: ForgotPassword,
      },
      {
        path: "sign-in",
        name: "SignIn",
        component: Landing,
      },
      {
        path: "set-password/:token",
        name: "setPassword",
        component: SetPassword,
      },
      {
        path: "verify/:code",
        name: "verify",
        component: Verify,
      },
      {
        path: "request-credentials",
        component: RequestCredentials,
        name: "RequestCredentials",
      },
    ],
  },
];
