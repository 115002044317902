<template>
  <v-toolbar flat dense elevation="0">
    <div v-for="(item, i) in issueListFilterItems" :key="i">
      <v-menu
        offset-y
        :close-on-content-click="true"
        @input="selectField($event, item)"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-on="on"
            :attrs="attrs"
            filled
            elevation="0"
            :class="`text-capitalize mx-1 mt-n2 ${
              item.active ? 'filter-menu-active' : ''
            }`"
          >
            {{ item.label }} :
            <div class="text-no-wrap" v-if="!filteredItems">All</div>
            <div v-else>
              <span v-for="(f, i) in filteredItems" :key="i">
                <span v-if="item.label === i">
                  {{ f }}
                </span>
              </span>
            </div>
            <v-icon class="pl-3">fa-light fa-chevron-down</v-icon>
          </v-btn>
        </template>

        <v-list dense>
          <v-list-item dense>
            <v-text-field
              prepend-inner-icon="fa-solid fa-magnifying-glass"
              outlined
              :filled="search.length <= 0"
              placeholder="Search"
              dense
              v-model="search"
            />
          </v-list-item>

          <v-list-item-group v-model="selectedItemKey" active-class="">
            <div style="overflow-y: scroll; max-height: 50vh" class="mt-n3">
              <v-list-item
                v-for="(field, i) in filterItems[item.label]"
                :key="i"
                link
              >
                <template v-slot:default="{ active }">
                  <v-list-item-action>
                    <v-checkbox :input-value="active"></v-checkbox>
                  </v-list-item-action>

                  <v-list-item-title>
                    {{
                      typeof field === "object"
                        ? field.username || field.description
                        : field
                    }}
                  </v-list-item-title>
                </template>
              </v-list-item>
            </div>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </div>

    <v-list-item v-if="canClear">
      <v-btn color="primary" text @click="refresh">
        Clear
        <v-icon class="ml-1 mt-1">mdi-close</v-icon>
      </v-btn>
    </v-list-item>
  </v-toolbar>
</template>
<script>
import { AuthService } from "../../../../dashboard/modules/auth";

export default {
  name: "ProjectsFilter",

  data: function () {
    return {
      selectedItem: [],
      selectedField: null,
      search: "",
      canClear: false,
    };
  },
  computed: {
    user() {
      return AuthService.user;
    },
    filteredItems: function () {
      return this.$store.getters["Ticket/issueGetters"]("filteredItems");
    },
    selectedItemKey: {
      get() {
        return [];
      },
      set(val) {
        const itemType = this.getFieldType(this.selectedField);
        const value = this.filterItems[this.getSelectedField].filter(
          (item, index) => {
            return val === index;
          }
        )[0].code;
        const obj = {};
        Object.assign(obj, { [itemType]: value });
        this.$store.dispatch("Ticket/getfilteredCode", obj);
        this.canClear = true;
      },
    },
    issueListFilterItems: {
      get() {
        return this.$store.getters["Ticket/issueGetters"](
          "issueListFilterItems"
        );
      },
      set(val) {
        this.$store.dispatch("Ticket/customFilter", val);
      },
    },
    assignees: function () {
      return this.$store.getters["Setup/setupGetters"]("assignees").map(
        (el) => {
          return {
            code: el.code,
            description: el.name,
          };
        }
      );
    },
    reporters: function () {
      return this.$store.getters["Setup/setupGetters"]("reporters")
        .map((el) => {
          return {
            code: el.code,
            description: el.name,
            project: el.project,
          };
        })
        .filter((el) => el.project === this.user.company);
    },
    clients: function () {
      return this.$store.getters["Setup/setupGetters"]("clients").map((el) => {
        return {
          code: el.id,
          description: el.first_name + " " + el.last_name,
        };
      });
    },
    filterItems: function () {
      return {
        assignee: this.assignees
          ? this.selectedField
            ? this.assignees.filter((el) => {
                return this.selectedField.label === "assignee"
                  ? el.code.toLowerCase().includes(this.search.toLowerCase())
                  : el;
              })
            : this.assignees
          : [],

        requestedBy: this.reporters
          ? this.selectedField
            ? this.reporters.filter((el) => {
                return this.selectedField.label === "requestedBy"
                  ? el.description
                      .toLowerCase()
                      .includes(this.search.toLowerCase())
                  : el;
              })
            : this.reporters
          : [],

        status: this.selectedField
          ? this.$store.getters["Setup/setupGetters"]("issueStatus").filter(
              (el) => {
                return this.selectedField.label === "status"
                  ? el.description
                      .toLowerCase()
                      .includes(this.search.toLowerCase())
                  : el;
              }
            )
          : this.$store.getters["Setup/setupGetters"]("issueStatus"),

        priorities: this.selectedField
          ? this.$store.getters["Setup/setupGetters"]("issuePriorities").filter(
              (el) => {
                return this.selectedField.label === "priorities"
                  ? el.description
                      .toLowerCase()
                      .includes(this.search.toLowerCase())
                  : el;
              }
            )
          : this.$store.getters["Setup/setupGetters"]("issuePriorities"),
      };
    },
    issues: function () {
      return this.$store.getters["Setup/setupGetters"]("projectIssues").filter(
        (d) => {
          return d.assignee === this.user.username;
        }
      );
    },
    getSelectedField: function () {
      if (this.selectedField !== null) {
        return this.selectedField.label;
      } else {
        return null;
      }
    },
    getSelectedItemPos: function () {
      if (this.selectedItem.length !== 0) {
        return this.selectedItem[0];
      } else {
        return null;
      }
    },
  },

  methods: {
    selectField: function (e, item) {
      const data = [...this.issueListFilterItems];
      if (e) {
        this.selectedField = item;
        this.issueListFilterItems = this.activateField(item, data);
        if (!this.getSelectedValue) {
          this.$store.dispatch("Ticket/getfilteredCode", this.getSelectedValue);
        }
      }
    },
    activateField: function (item, data) {
      return data.map((el) => {
        el.active = el.label === item.label;
        return el;
      });
    },
    getFieldType: function (item) {
      switch (item.label) {
        case "projects":
          return "projectCode";
        case "assignee":
          return "assignee";
        case "requestedBy":
          return "requestedBy";
        case "status":
          return "status";
        case "priorities":
          return "priorities";

        default:
          return "projectCode";
      }
    },
    refresh() {
      this.$store.dispatch("Ticket/removeFilters");
      this.canClear = false;
    },
  },
};
</script>
<style lang="sass">
@import '~@/packages/tickets/modules/issues/styles/issues.sass'
</style>
