<template>
  <v-app id="inspire" style="background-color: #e4edfd8c">
    <AppBarTop />
    <div>
      <router-view name="view" />
      <router-view name="action" />
      <router-view name="change" />
      <router-view name="user_mngmt" />
    </div>
    <spinner />
    <confirmation />
  </v-app>
</template>

<script>
import Spinner from "../../../plugins/loader/views/Spinner";
import AppBarTop from "../components/Bars/AppBarTop.vue";
import { AuthService } from "../../auth";
export default {
  name: "DashboardLayout",
  components: { Spinner, AppBarTop },
  data: () => ({
    drawer: null,
    leftDrawer: false,
    mini: false,
    // selectedItem: 0,
    fav: true,
    menu: false,
    message: false,
    hints: true,
  }),

  computed: {
    user() {
      return AuthService.user;
    },
    company() {
      return this.$store.getters["Landing/company"];
    },
    links() {
      return this.$store.getters["Dashboard/links"]
        .filter((item) => !item.disabled && item.title !== "Administration")
        .map((link) => {
          link.sublinks = link.sublinks || [];
          return link;
        });
    },
    adminLinks() {
      return this.$store.getters["Dashboard/links"]
        .filter((item) => !item.disabled && item.title === "Administration")
        .map((link) => {
          link.sublinks = link.sublinks || [];
          return link;
        });
    },
    actions() {
      return this.$store.getters["Dashboard/actions"];
    },
    menus() {
      return this.$store.getters["Dashboard/menus"];
    },
  },
  methods: {
    logout() {
      AuthService.logout();
    },
  },
};
</script>

<style>
@import url("../styles.css");
</style>
