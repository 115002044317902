<template>
  <v-scroll-y-transition leave-absolute v-if="issue.childIssues">
    <div flat class="ml-n4">
      <v-toolbar flat height="50%">
        <span
          class="text-h6 text--secondary"
          v-if="issue.childIssues.length > 0"
        >
          Child Issues
        </span>

        <span
          class="text-body-1 font-weight-bold"
          v-if="issue.childIssues.length === 0 && addChildIssue"
        >
          Add Child Issue
        </span>

        <v-spacer />

        <v-tooltip top v-if="addChildIssue">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              small
              @click="addChildIssue = true"
            >
              <v-icon>fa-solid fa-plus</v-icon>
            </v-btn>
          </template>

          <span>create new child issue</span>
        </v-tooltip>
      </v-toolbar>

      <v-list dense class="mt-n4" v-if="!editChildDesc">
        <v-list-item
          dense
          v-for="(childIssue, i) in issue.childIssues"
          :key="i"
          class="my-2"
        >
          <v-toolbar dense elevation="1">
            <v-list-item-content>
              <span class="text-capitalize" v-html="childIssue.description" />
            </v-list-item-content>

            <!-- avatar -->
            <v-list-item-avatar>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-avatar
                    v-on="on"
                    v-bind="attrs"
                    class="mr-2 white--text"
                    :size="24"
                    :color="
                      childIssue.assignee
                        ? userColor(childIssue.assignee)
                        : 'grey'
                    "
                  >
                    <span v-if="!childIssue.assignee">
                      <v-icon>fa-solid fa-user</v-icon>
                    </span>

                    <span v-else>{{ childIssue.assignee }}</span>
                  </v-avatar>
                </template>

                <span>
                  {{ childIssue.assignee || "Unassigned" }}
                </span>
              </v-tooltip>
            </v-list-item-avatar>

            <!-- status -->
            <v-list-item-content class="d-flex">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :class="`${issueStatusColor(
                      childIssue.status
                    )}--text text--darken-4 text-capitlize`"
                    small
                    v-on="on"
                    v-bind="attrs"
                    elevation="0"
                    :color="
                      childIssue.status
                        ? `${issueStatusColor(childIssue.status)} lighten-5`
                        : 'white'
                    "
                  >
                    {{ childIssue.status || "update status" }}
                    <v-icon class="mt-0">mdi-chevron-down</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    v-for="status in Statuses"
                    :key="status.code"
                    link
                    @click="changeStatus(status.code, childIssue)"
                  >
                    <v-list-item-title>
                      {{ status.description }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-list-item-content>

            <v-list-item-action>
              <div class="d-flex">
                <v-btn icon x-small @click="editChildInfo(childIssue)">
                  <v-icon color="blue" small>mdi-pencil</v-icon>
                </v-btn>

                <v-btn icon x-small @click="deleteChildIssue(childIssue)">
                  <v-icon color="error" small>mdi-delete</v-icon>
                </v-btn>
              </div>
            </v-list-item-action>
          </v-toolbar>
        </v-list-item>
      </v-list>

      <div v-else class="mx-4">
        <v-text-field dense filled v-model="childIssueForm.description" />
        <div class="d-flex mt-3">
          <v-spacer></v-spacer>

          <v-btn depressed color="primary" @click="updateChildIssue()" icon>
            <v-icon>mdi-check</v-icon>
          </v-btn>

          <v-btn
            depressed
            color="error"
            text
            @click="editChildDesc = false"
            icon
          >
            <v-icon>mdi-cancel</v-icon>
          </v-btn>
        </div>
      </div>

      <v-list dense class="mt-n2" v-if="addChildIssue">
        <v-list-item dense class="add-childIssue-view">
          <v-list-item-content dense>
            <v-text-field
              outlined
              dense
              label="Child Issue Description"
              v-model="childIssueForm.description"
              placeholder="Enter Child Issue Description"
            />

            <div class="d-flex mt-n4">
              <v-btn
                text
                class="text-capitalize text-body-1 pa-2 mr-auto"
                tile
                small
              >
                <v-icon class="mx-2">fa-solid fa-magnifying-glass</v-icon>
                Choose an existing issue
              </v-btn>

              <v-btn
                class="text-capitalize pa-2 mx-2 text-body-1"
                color="primary"
                :disabled="childIssueForm.description.length === 0"
                small
                @click="createChildIssue"
              >
                Create
              </v-btn>

              <v-btn
                class="text-capitalize pa-2 text-body-1"
                color="deep-orange"
                @click="addChildIssue = false"
                small
                text
              >
                Cancel
              </v-btn>
            </div>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>
  </v-scroll-y-transition>
</template>

<script>
import issueMixin from "../issueMixin";
export default {
  name: "ChildIssues",
  mixins: [issueMixin],
  props: {
    issue: {
      type: Object,
      required: true,
    },
    displayChildForm: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      childIssueForm: {
        description: "",
        projectCode: "",
        dueDate: "",
        priorities: "",
        tags: "CHILD",
        parentIssue: "",
        lineNo: null,
      },
      editChildDesc: false,
    };
  },

  computed: {
    hasChildIssues() {
      return this.issue.childIssues.length > 0;
    },
    addChildIssue: {
      get() {
        return this.displayChildForm;
      },
      set(val) {
        this.$emit("closeChildIssue", val);
      },
    },
  },

  methods: {
    convertArrtoObj(val) {
      if (val !== 0 || val !== null) {
        return val
          ? `${val.first_name[0].toUpperCase()}${val.last_name[0].toUpperCase()}`
          : "N/A";
      } else {
        return "N/A";
      }
    },
    createChildIssue() {
      this.childIssueForm.projectCode = this.issue.projectCode;
      this.childIssueForm.dueDate = this.issue.dueDate;
      this.childIssueForm.priorities = this.issue.priorities;
      this.childIssueForm.parentIssue = this.issue.issueCode;
      this.addChildIssue = false;
      delete this.childIssueForm.lineNo;
      this.$store.dispatch("Ticket/addChildIssue", { ...this.childIssueForm });
      this.childIssueForm.description = "";
    },
    changeStatus(code, childIssue) {
      childIssue.status = code;
      this.$store.dispatch("Ticket/updateChildIssue", childIssue);
    },
    deleteChildIssue(item) {
      this.$store.dispatch("Ticket/deleteChildIssue", item);
    },
    editChildInfo(item) {
      this.childIssueForm = item;
      this.editChildDesc = true;
    },
    updateChildIssue() {
      this.$store.dispatch("Ticket/updateChildIssue", this.childIssueForm);
      this.editChildDesc = false;
    },
  },
};
</script>
