<template>
  <v-container fluid>
    <router-view />
    <Confirmation />
  </v-container>
</template>
<script>
import Confirmation from "@/packages/dashboard/plugins/confirmtion/Confirmation.vue";
export default {
  name: "Issues",
  components: { Confirmation },
  data: function () {
    return {};
  },
  beforeRouteEnter(to, from, next) {
    next(() => {
      //
    });
  },
};
</script>
