export default {
  login: "auth/login",
  passwordReset: "auth/password-reset",
  CHANGE_PASSWORD: "auth/change-password",
  password: `auth/change-details`,
  logout: "auth/logout",
  user: `auth/user`,
  setCompany: `auth/set-company`,
  APPLICATION_REGISTRATION: `applications/signup`,
  REQUEST_CREDENTIALS: (code) => `auth/request-credentials/${code}`,
  VERIFY_ACCOUNT: `auth/verify`,
};
