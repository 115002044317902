import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"mx-3 pl-12",attrs:{"width":"100%","flat":""}},[_c(VCardTitle,[_c('span',{staticClass:"text-lg-h4"},[_vm._v("Issues")])]),_c(VCardText,[_c('div',{staticClass:"d-flex justify-start",attrs:{"align":"start"}},[_c('div',[_c(VTextField,{attrs:{"id":"searchIssue","prepend-inner-icon":"fa-solid fa-magnifying-glass","outlined":"","filled":_vm.searchInput.length <= 0,"placeholder":"Search","dense":""},model:{value:(_vm.searchInput),callback:function ($$v) {_vm.searchInput=$$v},expression:"searchInput"}})],1),_c('div',{attrs:{"id":"issueStatus"}},[_c('ProjectsFilter')],1),_c(VBtn,{attrs:{"id":"addIssue","color":"primary","depressed":""},on:{"click":function($event){_vm.dialog = true}}},[_vm._v(" add issue ")])],1),_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.issues,"light":"","calculate-widths":"","search":_vm.searchInput,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"fixed-header":"","height":"70vh"},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"dblclick:row":_vm.selectIssue},scopedSlots:_vm._u([{key:"item.issueType",fn:function(ref){
var item = ref.item;
return [_c(VChip,{class:((_vm.issueTypeColor(
            item.issueType
          )) + "--text text--darken-4 text-capitlize"),attrs:{"label":"","small":"","color":((_vm.issueTypeColor(item.issueType)) + " lighten-5")}},[_vm._v(_vm._s(item.issueType))])]}},{key:"item.title",fn:function(ref){
          var item = ref.item;
return [_c('a',{on:{"click":function($event){return _vm.selectIssue($event, item)}}},[(item.title)?_c('span',{class:((_vm.issueTypeColor(
              item.issueType
            )) + "--text text--darken-4 text-capitlize"),domProps:{"innerHTML":_vm._s(
              item.title.length > 40
                ? ((item.title.slice(0, 40)) + "...")
                : item.title
            )}}):_vm._e()])]}},{key:"item.assignee",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[(item.assignee !== null)?_c(VAvatar,{staticClass:"mr-2 white--text",attrs:{"size":24,"color":_vm.userColor(item.assignee)}},[(item.assignee)?_c(VImg,{attrs:{"src":_vm.getAssigneeAvatar(item.assignee),"contain":""}}):_vm._e()],1):_c(VAvatar,{staticClass:"mr-2 white--text",attrs:{"color":"grey","size":24}}),(item.assignee !== null)?_c('span',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(_vm.getAssignee(item.assignee))+" ")]):_c('span',[_vm._v("_ _ _")])],1)]}},{key:"item.reporter",fn:function(ref){
            var item = ref.item;
return [(item.reporter || item.requestedBy)?_c('div',{staticClass:"d-flex"},[_c(VAvatar,{staticClass:"mr-2 white--text",attrs:{"size":24,"color":_vm.userColor(item.reporter)}},[(item.reporter)?_c(VImg,{attrs:{"src":_vm.getReporterAvatar(item.reporter),"contain":""}}):(item.requestedBy)?_c(VImg,{attrs:{"src":_vm.getReporterAvatar(item.requestedBy),"contain":""}}):_vm._e()],1),(item.reporter)?_c('span',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(_vm.getReporterName(item.reporter))+" ")]):(item.requestedBy)?_c('span',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(_vm.getReporterName(item.requestedBy))+" ")]):_vm._e()],1):_c('div',[_c(VAvatar,{staticClass:"mr-2 white--text",attrs:{"size":24,"color":_vm.userColor(item.reporter)}},[_vm._v(" "+_vm._s(item.reporter)+" ")]),_c('span',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(item.reporter)+" ")])],1)]}},{key:"item.priorities",fn:function(ref){
            var item = ref.item;
return [_c(VChip,{class:((_vm.issuePriorityColor(
            item.priorities
          )) + "--text text--darken-4 text-capitlize"),attrs:{"label":"","small":"","color":((_vm.issuePriorityColor(item.priorities)) + " lighten-5")}},[_vm._v(_vm._s(item.priorities))])]}},{key:"item.status",fn:function(ref){
          var item = ref.item;
return [_c(VChip,{class:((_vm.issueStatusColor(
            item.status
          )) + "--text text--darken-4 text-capitlize"),attrs:{"label":"","small":"","color":((_vm.issueStatusColor(item.status)) + " lighten-5")}},[_vm._v(_vm._s(item.status))])]}},{key:"item.createdDate",fn:function(ref){
          var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.dateFormat(item.createdDate)))])]}},{key:"item.updatedDate",fn:function(ref){
          var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.dateFormat(item.updatedDate)))])]}},{key:"item.dueDate",fn:function(ref){
          var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.dateFormat(item.dueDate)))])]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c(VMenu,{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c(VBtn,_vm._g({attrs:{"attrs":attrs,"icon":""}},on),[_c(VIcon,{attrs:{"size":"25"}},[_vm._v("fa-solid fa-ellipsis")])],1)]}}],null,true)},[_c(VList,{attrs:{"dense":"","id":"viewIssues"}},_vm._l((_vm.actions),function(action,i){return _c(VListItem,{key:i,attrs:{"dense":"","disabled":action.disabled},on:{"click":function($event){return action.action($event, item)}}},[_c(VListItemTitle,[_c(VIcon,{attrs:{"color":"blue"}},[_vm._v("mdi-pencil")]),_vm._v(" "+_vm._s(action.text)+" ")],1)],1)}),1)],1)]}}])})],1),_c(VDialog,{attrs:{"persistent":"","width":_vm.$vuetify.breakpoint.mdAndUp ? '70%' : '100%'},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('IssuesCard',{attrs:{"padding":_vm.padding,"dialog":_vm.dialog}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }