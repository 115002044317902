import AuthService from "@/packages/dashboard/modules/auth/AuthService";

export default {
  to: "/users",
  order: 3,
  icon: "fa-light fa-people-line",
  title: "User Management",
  disabled: AuthService.user
    ? AuthService.user["user_type"] === "CLIENT_ADMIN"
      ? false
      : true
    : false,
  hasSubs: true,
};
