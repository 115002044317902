import { VAlert } from 'vuetify/lib/components/VAlert';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VOtpInput } from 'vuetify/lib/components/VOtpInput';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","max-width":"400px"},model:{value:(_vm.OtpDialog),callback:function ($$v) {_vm.OtpDialog=$$v},expression:"OtpDialog"}},[_c(VCard,{staticClass:"mx-auto"},[_c(VAppBar,{attrs:{"dense":"","elevation":"0","color":"primary"}},[_c('div',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.params.title))])]),_c(VCardText,{staticClass:"mt-5 d-flex justify-center"},[_c('div',{staticStyle:{"max-width":"320px"}},[_c(VAlert,{attrs:{"type":_vm.alert.type,"dense":"","outlined":"","dismissible":"","prominent":""},model:{value:(_vm.showAlert),callback:function ($$v) {_vm.showAlert=$$v},expression:"showAlert"}},[_vm._v(" "+_vm._s(_vm.alert.message)+" ")]),_c('div',{staticClass:"ma-auto position-relative"},[_c('div',[_vm._v("Enter OTP Code")]),_c(VOtpInput,{attrs:{"length":"5","disabled":_vm.loading,"type":"number"},on:{"finish":_vm.verifyOtp},model:{value:(_vm.otpCode),callback:function ($$v) {_vm.otpCode=$$v},expression:"otpCode"}}),_c(VOverlay,{attrs:{"absolute":"","color":"grey lighten-4","light":"","value":_vm.loading}},[_c(VProgressCircular,{attrs:{"indeterminate":"","size":"50","width":"3","color":"primary"}})],1),_c('div',{staticClass:"d-flex flex-column align-end"},[_c('div',{staticClass:"d-flex flex-row"},[_c('div',{staticClass:"text-caption mt-1"},[_vm._v("Didn't receive the code?")]),_c(VBtn,{attrs:{"text":"","color":"orange lighten-1","small":""},on:{"click":_vm.resendCode}},[_c(VIcon,{staticClass:"ml-1"},[_vm._v("sync")]),_vm._v(" Resend Code ")],1)],1)])],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }