class Colors {
  issueType() {
    return {
      bug: "deep-orange",
      epic: "blue",
      improvement: "green",
      newfeature: "teal",
      task: "blue",
    };
  }

  issuePriority() {
    return {
      highest: "red",
      high: "deep-orange",
      medium: "teal",
      low: "blue",
      lowest: "lime",
    };
  }
}

export default new Colors();
