import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"signup-request-white auth fill-height d-flex flex-row align-center",attrs:{"fluid":""}},[_c(VRow,[_c(VCol,{attrs:{"md":"4","sm":"12","offset-md":"4"}},[_c(VCard,{staticClass:"pa-5"},[_c('div',{staticClass:"text-center"},[_c('Logo',{staticClass:"my-3",attrs:{"height":"150"}})],1),_c(VCardText,{staticClass:"text-justify mt-4 pb-0"},[_c(VForm,{ref:"requestAccessForm",on:{"submit":function($event){$event.preventDefault();return _vm.requestCredentials.apply(null, arguments)}}},[_c(VAlert,{attrs:{"type":"info","text":""}},[_vm._v(" Enter Your email address to activate. ")]),_c(VTextField,{ref:"email",attrs:{"dense":"","outlined":"","type":"email","label":"Email Address","prepend-inner-icon":"email","placeholder":"Enter your Email Address","rules":_vm.rules.email},model:{value:(_vm.formData.email),callback:function ($$v) {_vm.$set(_vm.formData, "email", $$v)},expression:"formData.email"}})],1)],1),_c(VCardActions,{},[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('span',{staticClass:"me-2"},[_vm._v("Already have an account?")]),_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{ name: 'SignIn' }}},[_vm._v(" Sign In ")])],1),_c(VCol,{attrs:{"cols":"12"}},[(!_vm.submitting)?_c(VBtn,{attrs:{"color":"primary","block":""},on:{"click":_vm.requestCredentials}},[_vm._v(" Send Activation Link ")]):_vm._e(),(_vm.submitting)?_c(VBtn,{attrs:{"color":"primary","block":""}},[_c('i',{staticClass:"fa fa-refresh fa-spin loading-button-spinner"}),_vm._v(" Processing...Please wait ")]):_vm._e()],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }