import DashboardLayout from "@/packages/dashboard/modules/dashboard/views/dashboardLayout";

export default [
  {
    path: "/users",
    component: DashboardLayout,
    children: [
      {
        path: "",
        redirect: "/users",
      },
    ],
  },
];
