import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VCard,[_c(VCardTitle,[_vm._v(" Users "),_c(VSpacer),_c(VBtn,{attrs:{"depressed":"","color":"primary"},on:{"click":function($event){_vm.dialog = true}}},[_vm._v(" send invite ")])],1),_c(VDivider),_c(VCardText,[_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.users},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VBtn,{attrs:{"color":"primary","plain":""},on:{"click":function($event){return _vm.sendInvitation(item)}}},[_vm._v(" invite ")])]}}])})],1)],1),_c('UsersCard',{attrs:{"dialog":_vm.dialog,"profile":_vm.profile},on:{"closeDialogEvent":_vm.closeDialogEvent}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }