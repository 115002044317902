import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
/** misc npm packages */
// import "roboto-fontface/css/roboto/roboto-fontface.css";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import "@fortawesome/fontawesome-free/css/all.css";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import VueEllipseProgress from "vue-ellipse-progress";
/** global mixins */
import listener from "@/packages/dashboard/mixin/listeners/listener";
import RouterMixin from "@/packages/dashboard/mixin/RouterMixin";
/** app global utils */
import { navigationType } from "@/packages/dashboard/utils/check-page-reload";
/** app plugins */
import vuetify from "./plugins/vuetify";
import appAlert from "@/packages/dashboard/plugins/appAlert";
import { filters } from "@/packages/dashboard/utils";
import confirmation from "./packages/dashboard/plugins/confirmtion";
import verify from "./packages/dashboard/plugins/verify";
/** app packages */
import dashboard from "./packages/dashboard";
import tickets from "./packages/tickets";
import setups from "./packages/setup";
import Profile from "./packages/Profile";
import UserManagement from "./packages/user_management";

import VueTour from "vue-tour";
import "./registerServiceWorker";

require("vue-tour/dist/vue-tour.css");

Vue.use(VueTour);

Vue.config.productionTip = false;

window.Event = new Vue();

const options = {
  store,
  router,
};

filters.forEach((f) => {
  Vue.filter(f.name, f.execute);
});

Vue.use(VueEllipseProgress);
Vue.use(Toast);
Vue.use(confirmation);
Vue.use(appAlert);
Vue.use(verify, options);
Vue.use(dashboard, options);
Vue.use(tickets, options);
Vue.use(setups, options);
Vue.use(Profile, options);
Vue.use(UserManagement, options);
Vue.use(navigationType);

new Vue({
  router,
  store,
  vuetify,
  mixins: [listener, RouterMixin],
  render: (h) => h(App),
}).$mount("#app");
