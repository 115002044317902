import { AuthService } from "../index";

export default function auth({ next, router }) {
  if (!AuthService.check()) {
    return router.replace({
      name: "SignIn",
    });
  }
  return next();
}
