import AuthService from "../AuthService";

export default function user({ next, router }) {
  if (AuthService.user["user_type"] !== "CLIENT_ADMIN") {
    return router.replace({
      name: "Main",
    });
  }
  return next();
}
