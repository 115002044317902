<!-- eslint-disable vue/valid-v-slot -->
<template>
  <v-container>
    <v-card>
      <v-card-title>
        Users
        <v-spacer></v-spacer>
        <v-btn depressed color="primary" @click="dialog = true">
          send invite
        </v-btn>
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text>
        <v-data-table :headers="headers" :items="users">
          <template v-slot:item.name="{ item }">
            <span class="text-capitalize">
              {{ item.name }}
            </span>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn color="primary" plain @click="sendInvitation(item)">
              invite
            </v-btn>
          </template>
          <!-- <template v-slot:item.is_verified="{ item }">
            <v-icon color="green">
              {{ item.is_verified === 1 ? "mdi-check" : "" }}
            </v-icon>
          </template> -->
        </v-data-table>
      </v-card-text>
    </v-card>

    <UsersCard
      :dialog="dialog"
      @closeDialogEvent="closeDialogEvent"
      :profile="profile"
    />
  </v-container>
</template>

<script>
import AuthService from "@/packages/dashboard/modules/auth/AuthService";
import UsersCard from "@/packages/user_management/components/UsersCard";

export default {
  name: "Users",
  components: { UsersCard },
  data() {
    return {
      dialog: false,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("UserManagement/getUsers");
    });
  },
  computed: {
    headers() {
      return [
        { text: "Code", value: "code", align: "start", sortable: true },
        { text: "Name", value: "name", align: "start", sortable: true },
        { text: "Phone", value: "phoneNo", align: "start", sortable: true },
        { text: "Email", value: "eMail", align: "start", sortable: true },
        {
          text: "User Type",
          value: "userType",
          align: "start",
          sortable: true,
        },
        { text: "Action", value: "actions", align: "start", sortable: true },
        // {
        //   text: "Verified",
        //   value: "is_verified",
        //   align: "start",
        //   sortable: true,
        // },
      ];
    },
    profile() {
      return AuthService.user;
    },
    users() {
      const users = this.$store.getters["UserManagement/userGetters"]("users");
      return users
        ? users.filter((item) => {
            return item.project === this.profile.company;
          })
        : [];
    },
  },
  methods: {
    closeDialogEvent: function (value) {
      this.dialog = value;
    },
    sendInvitation(item) {
      const formData = {
        email: item.eMail,
        company: item.company ? item.company : this.profile.company,
        user_code: item.code,
        name: item.name,
        phoneNo: item.phoneNo,
        user_type: item.userType,
      };
      this.$store.dispatch("UserManagement/sendInvitation", formData);
    },
  },
};
</script>
