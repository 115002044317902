import { AuthRoutes, AuthStore } from "./modules/auth";
import { landingRoutes, landingStore } from "./modules/landing";
import {
  dashboardRoutes,
  dashboardStore,
  dashboardLinks,
} from "./modules/dashboard";
import nextFactory from "./modules/auth/middleware/MiddlewareFactory";

export default {
  install(Vue, options) {
    // eslint-disable-next-line no-global-assign
    Event = new Vue();

    if (options.router) {
      options.router.addRoute(...dashboardRoutes);
      options.router.addRoute(...AuthRoutes);
      options.router.addRoute(...landingRoutes);

      options.router.beforeEach((to, from, next) => {
        if (to.meta.middleware) {
          const middleware = Array.isArray(to.meta.middleware)
            ? to.meta.middleware
            : [to.meta.middleware];

          const context = {
            from,
            next,
            router: options.router,
          };

          const nextMiddleware = nextFactory(context, middleware, 1);
          return middleware[0]({ ...context, next: nextMiddleware });
        }
        return next();
      });
    }

    if (options.store) {
      options.store.registerModule("Dashboard", dashboardStore);
      options.store.registerModule("Auth", AuthStore);
      options.store.registerModule("Landing", landingStore);
      options.store.dispatch("Dashboard/setLinks", dashboardLinks);
    }
  },
};
