<template>
  <div style="background-color: white">
    <v-row no-gutters class="flex">
      <v-col id="display1" cols="12" md="6" sm="6">
        <v-card
          flat
          tile
          height="100vh"
          class="mx-auto d-flex flex-column justify-center"
          elevation="0"
          color="primary"
        >
          <div class="login-photo mx-auto">
            <div>
              <v-img
                max-height="1500"
                :width="$vuetify.breakpoint.mdAndUp ? 400 : 'auto'"
                src="../../../../../assets/undraw_cloud_hosting_7xb1.svg"
              ></v-img>
            </div>
          </div>
          <div class="mt-5">
            <p class="font text-center text-wrap">
              <span class="white--text text-h5">
                Login to continue using the application
              </span>
            </p>
          </div>
          <div class="mt-5"></div>
        </v-card>
      </v-col>
      <v-col id="display2" cols="12" md="6" sm="6" class="my-auto">
        <Login />
      </v-col>
    </v-row>
    <spinner />
    <OtpVerification />
    <!--    <Footer opacity="0.65" />-->
  </div>
</template>

<script>
import { AuthService } from "../../auth";
import Spinner from "../../../plugins/loader/views/Spinner";
import OtpVerification from "../../../plugins/verify/OtpVerification.vue";
import Login from "../../auth/components/Login.vue";

export default {
  name: "Landing",
  data: function () {
    return {
      message: [
        "To Activate:",
        "Option 1: Get your student details by searching using your student Number/ID Number/Reg No./FullName/Surname e.g. CCT0000123 which will populate the neccessary fields below. then enter any blank fields (if there are any).",
        "Option 2: Enter blank fields below the search field then <strong> click on the ACTIVATE button </strong>",
        "Steps after clicking the <strong>ACTIVATE</strong> button:",
        [
          "An <strong> OTP code </strong> will be sent (via sms) to the Phone number and Email Entered.",
          "Enter the code on the Activation Code Field.",
          "Enter your password then confirm the passwrod your entered. (Passwords must match)",
          "Finally click on the <strong>SUBMIT</strong> button to activate.",
        ],
      ],
    };
  },
  components: {
    Spinner,
    OtpVerification,
    Login,
  },
  computed: {
    company() {
      return this.$store.getters["Landing/company"];
    },

    auth() {
      return AuthService;
    },
  },
};
</script>

<style>
@import url("../../auth/styles.css");
@media (max-width: 768px) {
  #flex {
    display: flex;
    flex-direction: column;
  }
  #display1 {
    order: 2;
  }
  #display2 {
    order: 1;
  }
}
</style>
