<template>
  <div>
    <v-card :class="`mx-auto px-3`" flat>
      <v-card-title>
        <span class="text-h5"> Create Issue</span>
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text
        class="overflow-y-auto overflow-x-hidden mt-3"
        style="max-height: 70vh"
      >
        <v-form v-model="isValid" ref="IssueForm">
          <v-row>
            <!-- title -->
            <v-col cols="12" class="mb-n6">
              <div class="d-flex flex-column">
                <span>Title <span class="red--text">*</span></span>
                <v-text-field
                  v-model="formData.title"
                  filled
                  single-line
                  dense
                  label="Title"
                  :rules="rules.required"
                />
              </div>
            </v-col>

            <!-- issue type -->
            <!-- <v-col cols="12" class="" md="6">
              <div class="d-flex flex-column">
                <span>Issue Type</span>
                <v-autocomplete
                  :items="IssueTypes"
                  :item-text="(item) => item.description"
                  :item-value="(item) => item.code"
                  v-model="formData.issueType"
                  filled
                  dense
                  single-line
                  label="Issue Type"
                  class="mt-1"
                />
              </div>
            </v-col> -->

            <!-- priority -->
            <v-col cols="12" class="" md="6">
              <div class="d-flex flex-column">
                <span>Issue Priority</span>
                <v-autocomplete
                  :rules="rules.required"
                  :items="priorities"
                  :item-text="(item) => item.description"
                  :item-value="(item) => item.code"
                  v-model="formData.priorities"
                  filled
                  dense
                  single-line
                  label="Issue Priority"
                  class="mt-1"
                />
              </div>
            </v-col>

            <!-- duedate -->
            <v-col cols="12" class="" md="6">
              <div class="d-flex flex-column">
                <span>Required By</span>

                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  ref="menu"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="formData.dueDate"
                      :rules="rules.required"
                      single-line
                      label="Select Date"
                      filled
                      dense
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>

                  <v-date-picker
                    v-model="formData.dueDate"
                    :min="new Date(Date.now()).toISOString().substr(0, 10)"
                    no-title
                    @change="save"
                    show-current=""
                    scrollable
                  />
                </v-menu>

                <span style="text-align: right" class="mt-n6 font-weight-bold">
                  {{ countdown }} days to go
                </span>
              </div>
            </v-col>

            <!-- module -->
            <v-col cols="12" class="mt-n5" md="6">
              <div class="d-flex flex-column">
                <span>Module</span>

                <v-autocomplete
                  :items="modules"
                  :item-text="(item) => item.name"
                  :item-value="(item) => item.moduleCode"
                  v-model="formData.moduleCode"
                  @input="filterModules($event)"
                  filled
                  dense
                  single-line
                  label="Module"
                  class="mt-1"
                />
              </div>
            </v-col>

            <!-- Product -->
            <v-col cols="12" class="mt-n5" md="6">
              <div class="d-flex flex-column">
                <span>Product</span>

                <v-autocomplete
                  :items="projectProducts"
                  :item-text="(item) => item.description"
                  :item-value="(item) => item.productCode"
                  v-model="formData.productCode"
                  filled
                  dense
                  single-line
                  label="Product"
                  class="mt-1"
                />
              </div>
            </v-col>

            <!-- description -->
            <v-col cols="12" class="mt-n5 mb-7">
              <div class="d-flex flex-column">
                <span>Description <span class="red--text">*</span></span>
                <Editor
                  class="mt-1 editor"
                  v-model="description"
                  :editorToolbar="customToolbar"
                  :editorOptions="editorSettings"
                  :rules="rules.required"
                />
              </div>
            </v-col>

            <!-- attachments -->
            <v-col cols="12" class="mt-n5">
              <div class="d-flex flex-column">
                <span class="mb-1">
                  Attachments
                  <span class="red--text">*</span>
                </span>

                <v-alert
                  type="error"
                  dense
                  text
                  icon="mdi-cloud-alert"
                  border="left"
                  v-if="attachmentError"
                >
                  Each attachment should be less than 2MB and not more than 5
                  attachments
                </v-alert>

                <div class="d-flex">
                  <div v-if="formData.attachments.length !== 0">
                    <v-list-item
                      v-for="(file, i) in formData.attachments"
                      :key="i"
                    >
                      <v-list-item-icon>
                        <v-chip label color="blue lighten-4" small>
                          <v-icon small color="blue">mdi-file-outline</v-icon>
                        </v-chip>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>
                          <div class="d-flex">
                            {{ file.name }}

                            <v-btn
                              icon
                              x-small
                              color="error lighten-1"
                              class="ml-16"
                              @click="deleteFile(file)"
                            >
                              <v-icon>mdi-delete</v-icon>
                            </v-btn>
                          </div>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </div>

                  <div
                    class="border"
                    @click="$refs.uploader.$refs.input.click()"
                  >
                    <div class="mx-auto d-flex">
                      <v-icon :size="40" class="mr-3">
                        mdi-cloud-upload-outline
                      </v-icon>

                      <div class="mt-10">
                        <span>Click here to add files</span>
                      </div>

                      <v-file-input
                        accept="application/pdf,.jpeg,.png,.jpg,GIF"
                        ref="uploader"
                        hide-input
                        prepend-icon=""
                        multiple
                        @change="uploadFile"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <div id="editorInput" style="display: none">
        <div
          style="display: none"
          class="ql-editor"
          v-html="description"
          id="quizOutput"
        ></div>
      </div>

      <v-divider></v-divider>

      <v-card-actions>
        <v-checkbox v-model="checkbox" label="Create another Issue" riiple />

        <v-spacer />

        <v-btn depressed plain color="error" class="mr-3" @click="closeDialog">
          cancel
        </v-btn>

        <v-btn depressed color="primary" @click="createIssue">
          {{ "insert" }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <DialogSpinner />

  </div>
</template>
<script>
import { Quill, VueEditor as Editor } from "vue2-editor";
import { ImageDrop } from "quill-image-drop-module";
import AuthService from "@/packages/dashboard/modules/auth/AuthService";
import helpers from "@/packages/dashboard/utils/helpers/helper.js";
import DialogSpinner from "@/packages/dashboard/plugins/loader/views/DialogSpinner.vue";
import EditorMixin from "../EditorMixin";

window.Quill = Quill;
const ImageResize = require("quill-image-resize-module").default;
Quill.register("modules/imageResize", ImageResize);
Quill.register("modules/imageDrop", ImageDrop);
export default {
  name: "IssuesCard",
  props: { padding: Number },
  mixins: [EditorMixin],
  components: {
    Editor,
    DialogSpinner,
    // ExcelUploadVue,
  },
  data() {
    return {
      route: false,
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["code-block"],
      ],
      editorSettings: {
        modules: {
          imageDrop: true,
          imageResize: {},
        },
      },
      menu: false,
      list: false,
      isValid: false,
      formData: {
        issueType: "",
        title: "",
        description: "",
        dueDate: "",
        priorities: "",
        attachments: [],
        moduleCode: "",
        productCode: "",
        issueComments: [],
      },
      checkbox: false,
      countdown: 0,
      description: "",
      attachmentError: false,
    };
  },
  mounted() {
    console.log(" I am just on right now!")
    Event.$on("clearingForm", () => {
      this.clearform();
    });
  },
  computed: {
    user() {
      return AuthService.user ? AuthService.user : this.$route.params;
    },
    IssueTypes() {
      return this.$store.getters["Setup/setupGetters"]("issueTypes");
    },
    agents() {
      return this.$store.getters["Setup/setupGetters"]("agents");
    },
    projects() {
      return this.$store.getters["Setup/setupGetters"]("projects");
    },
    priorities() {
      return this.$store.getters["Setup/setupGetters"]("issuePriorities");
    },
    rules() {
      return {
        required: [(v) => !!v || "field is required"],
      };
    },
    issue() {
      return this.$store.getters["Ticket/issueGetters"]("issue");
    },
    modules() {
      return this.$store.getters["Setup/setupGetters"]("modules").filter(
        (item) => item.projectCode === this.user.company
      );
    },
    projectProducts() {
      return this.$store.getters["Setup/setupGetters"](
        "projectProducts"
      ).filter((item) => item.projectCode === this.user.company);
    },
  },
  methods: {
    uploadFile: async function (value) {
      if (value) {
        if (value.length !== 0) {
          value.forEach((item) => {
            if (item.size > 2000000 || value.length > 5) {
              this.attachmentError = true;
            } else {
              console.log(value, item);
              this.formData.attachments.push(item);
              this.attachmentError = false;
            }
          });
        }
      }
    },
    save(date) {
      this.countdown = Math.ceil(
        (new Date(date).getTime() - new Date(Date.now()).getTime()) /
          (1000 * 3600 * 24)
      );
      this.$refs.menu.save(date);
      this.menu = false;
    },
    async createIssue() {
      if (!this.isValid) {
        this.$refs.IssueForm.validate();
      } else {
        const image = await this.getbase64Content(
          this.extractImages(document.getElementById("quizOutput"))
        );
        this.formData.description = this.htmlToString(
          document.getElementById("quizOutput")
        );
        const data = { ...this.formData };
        data.projectCode = this.user.company;
        const attachments = data.attachments;
        delete data.attachments;
        const formData = helpers.prepareFormData(data);
        if (image) {
          formData.append("base64file", image);
        }
        attachments.forEach((file) => {
          formData.append("attachments[]", file);
        });
        this.$store.dispatch("Ticket/createIssue", {
          data: formData,
          close: this.checkbox,
        });
        this.clearform();
      }
    },
    closeDialog: function () {
      this.$store.dispatch("Ticket/addIssue", false);
      this.clearform();
    },
    clearform: function () {
      this.formData = {
        issueType: "",
        title: "",
        description: "",
        dueDate: "",
        priorities: "",
        attachments: [],
        moduleCode: "",
        productCode: "",
      };
      this.description = "";
      this.$refs.IssueForm.resetValidation();
    },
    filterModules(code) {
      const theModule = this.modules
        .filter((item) => item.moduleCode === code)
        .shift();
      const projectProduct = this.projectProducts
        .filter((item) => item.productCode === theModule.productCode)
        .shift();
      return projectProduct
        ? (this.formData.productCode = projectProduct.productCode)
        : "";
    },
    deleteFile(file) {
      const index = this.formData.attachments.indexOf(file);
      this.formData.attachments.splice(index, 1);
    },
    // eslint-disable-next-line no-unused-vars
    handleImageAdded(file, Editor, cursorLocation, resetUploader) {},
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },
};
</script>
<style>
.border {
  height: 110px;
  border: 1.9px dashed #838383;
  background: rgba(227, 242, 253, 0.45) none repeat scroll 0% 0%;
  border-radius: 4px;
  padding-top: 10px;
  display: flex !important;
  cursor: pointer;
  width: 100%;
}
.v-input__slot::before {
  border-style: none !important;
}
.editor {
  width: 100%;
  height: 70%;
}
.ql-editor {
  min-height: 0 !important;
}
</style>
