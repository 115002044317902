<template>
  <v-container
    id="mainDashboard"
    fluid
    style="background-color: #f0f0f0"
    class="px-5"
  >
    <v-row class="d-flex justify-space-between">
      <v-col cols="12" md="3">
        <v-card flat>
          <v-card-text>
            <v-row>
              <v-col cols="6" md="5" class="d-flex justify-center">
                <v-avatar size="40" color="indigo lighten-4" class="mx-auto">
                  <v-avatar size="30" color="indigo" class="mx-auto">
                    <v-icon size="10" color="white" class="mx-auto">
                      fa fa-folder-open
                    </v-icon>
                  </v-avatar>
                </v-avatar>
              </v-col>

              <v-col class="d-flex flex-column justify-center" cols="6" md="7">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      v-bind="attrs"
                      v-on="on"
                      class="text-h4 primary--text mx-auto truncate"
                    >
                      {{ user ? user.company : "N/A" }}
                    </div>
                  </template>

                  <span>{{ user ? user.company : "N/A" }}</span>
                </v-tooltip>

                <span class="text-h7 ma-auto text-uppercase">project</span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="2">
        <v-card flat>
          <v-card-text>
            <v-row>
              <v-col cols="6" md="5" class="d-flex justify-center">
                <v-avatar size="40" color="pink lighten-4" class="mx-auto">
                  <v-avatar size="30" color="pink" class="mx-auto">
                    <v-icon size="10" color="white">fa fa-ticket</v-icon>
                  </v-avatar>
                </v-avatar>
              </v-col>

              <v-col class="d-flex flex-column justify-center" cols="6" md="7">
                <span class="text-h4 primary--text mx-auto">
                  {{ issues ? issues.length : "0" }}
                </span>
                <span class="text-h7 ma-auto text-uppercase">tickets</span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="2">
        <v-card flat>
          <v-card-text>
            <v-row>
              <v-col cols="6" md="5" class="d-flex justify-center">
                <v-avatar size="40" color="blue-grey lighten-4" class="mx-auto">
                  <v-avatar size="30" color="blue-grey" class="mx-auto">
                    <v-icon size="10" color="white"
                      >mdi-lock-open-variant-outline</v-icon
                    >
                  </v-avatar>
                </v-avatar>
              </v-col>

              <v-col class="d-flex flex-column justify-center" cols="6" md="7">
                <span class="text-h4 primary--text mx-auto">
                  {{ closedIssues ? issues.length - closedIssues.length : "0" }}
                </span>

                <span class="text-h7 ma-auto text-uppercase">open</span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="2">
        <v-card flat>
          <v-card-text>
            <v-row>
              <v-col cols="6" md="5" class="d-flex justify-center">
                <v-avatar size="40" color="green lighten-4" class="mx-auto">
                  <v-avatar size="30" color="green" class="mx-auto">
                    <v-icon size="10" color="white">fa fa-check</v-icon>
                  </v-avatar>
                </v-avatar>
              </v-col>

              <v-col class="d-flex flex-column justify-center" cols="6" md="7">
                <span class="text-h4 primary--text mx-auto">
                  {{ closedIssues ? closedIssues.length : "0" }}
                </span>
                <span class="text-h7 ma-auto text-uppercase">closed</span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="2">
        <v-card flat>
          <v-card-text>
            <v-row>
              <v-col cols="6" md="5" class="d-flex justify-center">
                <v-avatar size="40" color="orange lighten-4" class="mx-auto">
                  <v-avatar size="30" color="orange" class="mx-auto">
                    <v-icon size="10" color="white">fa fa-list</v-icon>
                  </v-avatar>
                </v-avatar>
              </v-col>

              <v-col class="d-flex flex-column justify-center" cols="6" md="7">
                <span class="text-h4 primary--text mx-auto">
                  {{ unassignedIssues ? unassignedIssues.length : "0" }}
                </span>

                <span class="text-h7 ma-auto text-uppercase"> Unassigned </span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <IssueList />
    </v-row>
    <v-tour name="landingTour" :steps="steps" :options="myOptions"></v-tour>
  </v-container>
</template>
<script>
import IssueList from "../../../../tickets/modules/issues/components/IssueList.vue";
import AuthService from "@/packages/dashboard/modules/auth/AuthService";
export default {
  name: "MainDashboard",
  components: { IssueList },
  data: function () {
    return {
      steps: [
      {
          target: "#welcome",
          header: {
            title: "Welcome",
          },
          content: "This in-app guide is here to assist you in using our app to its fullest potential. We will provide tips, information, and guidance on our app's features and functionalities, as well as answer common questions.",
        },
        {
          target: "#addIssue",
          header: {
            title: "Create New Issue",
          },
          content: "Click the 'Add Issue' button to create a new issue.",
        },
        {
          target: "#searchIssue",
          heading: { title: "Search for Issues" },
          content: "You can search for your issue here!",
        },
        {
          target: "#issueStatus",
          header: {
            title: "Issues filters",
          },
          content:
            "Here are options for filtering issues. You can double click on the individual issue to view history or close the issue.",
          params: {
            placement: "top",
          },
        },
      ],
      myOptions: {
        labels: {
          buttonSkip: "Exit",
          buttonPrevious: "Back",
          buttonNext: "Next",
          buttonStop: "Finish",
        },
        highlight: true,
      },
    };
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Setup/getProjects");
      v.$store.dispatch("Setup/getissueTypes");
      v.$store.dispatch("Setup/getStatuses");
      v.$store.dispatch("Setup/getPriorities");
      v.$store.dispatch("Setup/getprojectProducts");
      v.$store.dispatch("Setup/getprojectProductModules");
      v.$store.dispatch("Ticket/getIssues", {
        projectCode: v.user.company,
      });
      v.$store.dispatch("Profile/getMyProfile");
      v.$store.dispatch("Setup/getAssignees");
      v.$store.dispatch("Setup/getReporters");
    });
  },
  mounted() {
    this.$tours["landingTour"].start();
  },
  computed: {
    user() {
      return AuthService.user;
    },
    Projects() {
      const projects = this.$store.getters["Setup/setupGetters"]("projects");
      return projects.filter((item) => {
        return item.code === this.user.company;
      });
    },
    issues() {
      return this.$store.getters["Ticket/issueGetters"]("issues");
    },
    closedIssues() {
      return this.issues
        ? this.issues.filter((item) => item.status === "DONE")
        : [];
    },
    unassignedIssues() {
      return this.issues ? this.issues.filter((item) => !item.assignee) : [];
    },
  },
};
</script>
<style>
.bordered {
  width: 7px;
  height: 30px;
  border-radius: 4px;
  border-style: solid;
  border-width: 0px 0px 0px 20px;
}

.height {
  height: 100vh;
}

.truncate {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: green;
}
</style>
