import DashboardLayout from "./views/dashboardLayout";
import Auth from "../auth/middleware/Auth";
// import AdminMiddleware from "../auth/middleware/AdminMiddleware";
import MainDashboard from "./components/MainDashboard";
import EditIssueView from "../../../tickets/modules/issues/components/EditIssueView.vue";
import Users from "@/packages/user_management/views/Users.vue";
import UsersCard from "@/packages/user_management/components/UsersCard.vue";
import Admin from "@/packages/dashboard/modules/auth/middleware/AdminMiddleware";

export default [
  {
    path: "/dashboard",
    component: DashboardLayout,
    children: [
      {
        path: "",
        redirect: "/dashboard/main",
      },
      {
        path: "main",
        name: "Main",
        components: {
          view: MainDashboard,
        },
        meta: { middleware: [Auth] },
      },
      {
        path: "edit_issue/:id",
        name: "EditIssueView",
        components: {
          view: MainDashboard,
          action: EditIssueView,
        },
        meta: { middleware: [Auth] },
      },
      {
        path: "/users",
        name: "Users",
        meta: { middleware: [Admin, Auth] },
        components: {
          user_mngmt: Users,
        },
        children: [
          {
            path: "usersList",
            name: "UsersList",
            component: UsersCard,
            meta: { middleware: [Admin, Auth] },
          },
        ],
      },
    ],
  },
];
