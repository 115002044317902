import ProfileConstants from "./ProfileConstants";
import { call } from "../dashboard/service";
import AuthService from "@/packages/dashboard/modules/auth/AuthService";

export default {
  namespaced: true,
  state: {
    profile: {},
  },
  mutations: {
    MUTATE: (state, payload) => {
      state[payload.state] = payload.data;
    },
  },
  getters: {
    profileGetters: (state) => (val) => state[val],
  },
  actions: {
    getMyProfile({ commit }) {
      call("get", ProfileConstants.PROFILE)
        .then((res) => {
          commit("MUTATE", { state: "profile", data: res.data.data });
        })
        .catch((error) => {
          console.error("Failed To Fetch Profile", error.response.data.message);
        });
    },
    updateMyProfile({ commit, dispatch }, data) {
      commit("Dashboard/SET_LOADING", true, { root: true });
      call("post", ProfileConstants.UPDATE_MY_PROFILE(data.id), data.data)
        .then((res) => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          Event.$emit("ApiSuccess", res.data.message);
          data.passwordReset ? AuthService.logout() : dispatch("getMyProfile");
        })
        .catch((error) => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", error.response.data.message);
        });
    },
  },
};
