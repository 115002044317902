import routes from "./routes";
import store from "./store";
import links from "./link";

export default {
  install(Vue, options) {
    if (options.router) {
      options.router.addRoute(...routes);
    }
    if (options.store) {
      options.store.registerModule("UserManagement", store);
      options.store.commit("Dashboard/SET_LINKS", links);
    }
  },
};
