import moment from "moment/moment";
import mime from "mime-types";
const CrytoJs = require("crypto-js");
// import { fromBuffer } from 'file-type'

class Helper {
  isEmpty(obj) {
    for (var prop in obj) {
      // eslint-disable-next-line no-prototype-builtins
      if (obj.hasOwnProperty(prop)) return false;
    }
    return true;
  }

  prepareFormData(formData) {
    const data = new FormData();
    for (const key in formData) {
      if (formData[key] === null) formData[key] = "";
      data.append(key, formData[key]);
    }
    return data;
  }

  stringify(object) {
    for (const key in object) {
      if (typeof object[key] === "object") {
        object[key] = JSON.stringify(object[key]);
      }
    }
    return object;
  }

  getFileIconColor(fileName) {
    if (!fileName) return "#727071";
    const fileExtension = fileName.split(".").pop();
    switch (fileExtension) {
      case "docx":
        return "#0276c0";
      case "pdf":
        return "#e62f34";
      case "pptx":
        return "#f39301";
      case "xls":
        return "#82bd8d";
      case "xlsx":
        return "#82bd8d";
      case "png":
        return "#a7ce59";
      case "jpg":
        return "#a7ce59";
      default:
        return "#727071";
    }
  }

  getFileIcon(fileName) {
    if (!fileName) return "fa fa-file-o";
    const fileExtension = fileName.split(".").pop();
    switch (fileExtension) {
      case "docx":
        return "fa fa-file-word";
      case "pdf":
        return "fa fa-file-pdf";
      case "pptx":
        return "fa fa-file-powerpoint";
      case "xls":
        return "fa fa-file-excel";
      case "xlsx":
        return "fa fa-file-excel";
      case "png":
        return "fa fa-file-image";
      case "jpg":
        return "fa fa-file-image";
      default:
        return "fa fa-file";
    }
  }

  getEntryDateFormat(date) {
    return moment(date).format("Do dddd, MMM YYYY hh:mm:ss a");
  }

  getDateFormat(date) {
    return moment(date).format("Do, MMM YYYY");
  }

  getTimeFormat(time) {
    return moment(time, "HH:mm:ss").format("hh:mm A");
  }

  detectMimeType(b64) {
    const signatures = {
      JVBERi0: "application/pdf",
      iVBORw0KGgo: "image/png",
      "/9j/": "image/jpg",
      UEsDBBQABgAIAAAAIQA:
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      UEsDBBQABgAIAAAAIQB:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    };
    for (var s in signatures) {
      if (b64.indexOf(s) === 0) {
        return signatures[s];
      }
    }
  }

  fileExtension(b64) {
    const signatures = {
      JVBERi0: ".pdf",
      iVBORw0KGgo: ".png",
      "/9j/": ".jpeg",
    };
    for (var s in signatures) {
      if (b64.indexOf(s) === 0) {
        return signatures[s];
      }
    }
  }

  toBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }
  encode(value) {
    return window.btoa(value);
  }
  decode(value) {
    return window.atob(value);
  }
  /** Get File Blob */
  async getFileBlob(url, fileName) {
    const response = await fetch(url);
    const data = await response.blob();
    const metadata = {
      type: mime.lookup(url),
    };
    return new File([data], fileName, metadata);
  }
  // async base64ToBlob(base64String) {
  //   const response = await fetch(base64String);
  //   const data = await response.blob();
  //   const metadata = {
  //     type: mime.lookup(base64String),
  //   };
  //   return new File([data], Math.random().toString(36).substr(2, 10), metadata);
  // }
  encrypt(value) {
    return CrytoJs.AES.encrypt(
      value,
      process.env.VUE_APP_AES_ALGORITHM_KEY,
      160
    ).toString();
  }
  decrypt(value) {
    return CrytoJs.AES.decrypt(
      value,
      process.env.VUE_APP_AES_ALGORITHM_KEY
    ).toString(CrytoJs.enc.Utf8);
  }
  status() {
    return {
      accepted: "blue",
      approved: "purple",
      closed: "red",
      draft: "indigo",
      declined: "red",
      done: "green",
      inprogress: "teal",
      inreview: "orange",
      new: "deep-orange",
      open: "amber",
      pending: "orange",
      posted: "cyan",
      reopened: "teal",
      readyfortesting: "purple",
      resolved: "navy",
      rejected: "red",
      shelved: "brown",
      todo: "blue",
      testing: "blue",
    };
  }
  stringToColour(str) {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    var colour = "#";
    for (let i = 0; i < 3; i++) {
      var value = (hash >> (i * 8)) & 0xff;
      colour += ("00" + value.toString(16)).substr(-2);
    }
    return colour;
  }
}
export default new Helper();
