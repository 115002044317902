<template>
  <v-row>
    <v-col
      cols="12"
      md="4"
      sm="3"
      v-for="(attachment, i) in attachments"
      :key="i"
    >
      <v-sheet
        :color="fileMeta(attachment.attachment).color"
        class="d-flex"
        dark
        outlined
        rounded
      >
        <v-row no-gutters>
          <v-col cols="9">
            <v-list-item link @click="viewDoc(attachment)" class="pr-0">
              <v-list-item-icon class="mr-0">
                <v-icon>
                  {{ fileMeta(attachment.attachment).icon }}
                </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>
                  <span
                    v-text="
                      attachment.fileName.length > 10
                        ? attachment.fileName.slice(0, 10)
                        : attachment.fileName
                    "
                  ></span>
                  <!-- {{ attachment.fileName }} -->
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-col>

          <v-col cols="3" class="d-flex justify-start align-center">
            <v-list-item>
              <v-list-item-icon>
                <v-icon @click="deleteAttachment(attachment)">
                  mdi-delete
                </v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-col>
        </v-row>
      </v-sheet>
    </v-col>

    <v-dialog
      v-model="dialog"
      persistent
      :width="$vuetify.breakpoint.mdAndUp ? '60%' : '100%'"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            label
            small
            dense
            dark
            @click="download(document.download)"
            class="ml-3"
          >
            <v-icon>mdi-download-circle</v-icon>Download
          </v-btn>
        </v-card-title>

        <v-divider></v-divider>

        <div v-if="show === true">
          <!-- pdf -->
          <v-card-text
            v-if="document.fileExtension === 'pdf'"
            class="overflow-y-auto overflow-x-hidden mt-3"
            style="max-height: 70vh"
          >
            <v-card-text
              class="elevation-2 mb-2"
              v-for="i in document.pages"
              :key="i"
            >
              <pdf
                style="width: 100%"
                :src="`${url}${document.attachment}`"
                :page="i"
              />
            </v-card-text>
          </v-card-text>

          <!-- image -->
          <v-card-text
            v-else
            class="overflow-y-auto overflow-x-hidden mt-3"
            style="max-height: 70vh"
          >
            <v-img class="pa-9" :src="url + document.attachment" />
          </v-card-text>
        </div>

        <!-- skeleton loader -->
        <v-card-text
          v-else
          class="overflow-y-auto overflow-x-hidden mt-3"
          style="max-height: 70vh"
        >
          <v-skeleton-loader type="image" />
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            dark
            depressed
            color="error"
            plain
            @click="(dialog = false), (show = false)"
          >
            close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import helper from "@/packages/dashboard/utils/helpers/helper.js";
import issueMixin from "../issueMixin";
import pdf from "vue-pdf";

export default {
  name: "AttachmentView",
  props: {
    attachments: {
      type: Array,
      default: () => [],
    },
  },
  mixins: [issueMixin],
  components: { pdf },
  data() {
    return {
      dialog: false,
      document: null,
      show: false,
    };
  },
  methods: {
    fileMeta: function (file) {
      return {
        icon: helper.getFileIcon(file),
        color: helper.getFileIconColor(file),
      };
    },
    viewDoc(attachment) {
      const link = attachment.attachment;
      const fileExtension = link.split(".").pop();
      helper.getFileBlob(this.url + link, attachment.fileName).then((res) => {
        Promise.all([helper.toBase64(res)]).then((res) => {
          res = res[0].split(",")[1];
          attachment.download = res;
          if (this.ifPreview(fileExtension)) {
            attachment.pages =
              fileExtension === "pdf" ? this.pageCount(attachment.download) : 0;
            this.dialog = true;
          } else {
            this.download(res);
          }
        });
        this.document = attachment;
        this.document.fileExtension = fileExtension;
      });
    },
    download(item) {
      var a = document.createElement("a");
      a.href = `data:${helper.detectMimeType(item)};base64,${item}`;
      a.download = `${this.document.fileName}`;
      a.click();
    },
    deleteAttachment(item) {
      this.$store.dispatch("Ticket/deleteAttachment", item);
    },
  },
  watch: {
    document: {
      handler: function (n) {
        if (n.length !== 0) {
          setTimeout(() => {
            this.show = true;
          }, 1000);
        }
      },
    },
  },
};
</script>
