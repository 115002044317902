import { call } from "@/packages/dashboard/service";
import constants from "./issueConstants";

export default {
  namespaced: true,
  state: {
    layout: "list-layout",
    issues: [],
    agents: [],
    issue: {},
    excelTemplate: "",
    issuesModal: false,
    issueListFilterItems: [
      {
        label: "assignee",
        selectedValue: "",
      },
      {
        label: "requestedBy",
        selectedValue: "",
      },
      {
        label: "status",
        selectedValue: "",
      },
      {
        label: "priorities",
        selectedValue: "",
      },
    ],
    selectedItem: {},
    selfLoading: {
      status: false,
      dueDate: false,
      priorities: false,
      id: false,
      comments: false,
    },
    logs: [],
    commentLogs: [],
    filteredItems: {},
  },
  mutations: {
    SET_LAYOUT: (state, payload) => {
      state.layout = payload;
    },
    MUTATE: (state, payload) => {
      state[payload.state] = payload.data;
    },
    ADD_FILTERS: (state, payload) => {
      if (payload) {
        if (Object.keys(payload) in state.filteredItems) {
          delete state.filteredItems[Object.keys(payload)];
          Object.assign(state.filteredItems, { ...payload });
        } else {
          Object.assign(state.filteredItems, { ...payload });
        }
        Event.$emit("getfilteredCode", state.filteredItems);
      }
    },
    REMOVE_FILTERS: (state) => {
      state.filteredItems = {};
      Event.$emit("removeFilters", state.filteredItems);
    },
  },
  getters: {
    issueGetters: (state) => (val) => state[val],
  },
  actions: {
    updateSelectedItem: ({ commit }, payload) => {
      commit("MUTATE", { state: "selectedItem", data: payload });
    },
    getIssues: ({ commit }, params) => {
      commit("Dashboard/SET_LOADING", true, { root: true });
      call("get", constants.ISSUES(params))
        .then((res) => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          commit("MUTATE", { state: "issues", data: res.data.data });
        })
        .catch((error) => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", error.response.data.message);
        });
    },
    customFilter({ commit }, payload) {
      commit("MUTATE", { state: "issueListFilterItems", data: payload });
    },
    addIssue({ commit }, payload) {
      commit("MUTATE", { state: "issuesModal", data: payload });
    },
    getIssue({ commit }, payload) {
      commit("Dashboard/SET_LOADING", true, { root: true });
      call("get", constants.ISSUE(payload))
        .then((res) => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          commit("MUTATE", { state: "selectedItem", data: res.data.data[0] });
        })
        .catch((error) => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", error.response.data.message);
        });
    },
    createIssue: ({ commit, dispatch }, data) => {
      commit("Dashboard/SET_DIALOG_LOADING", true, { root: true });
      call("post", constants.ADD_ISSUE, data.data)
        .then((res) => {
          commit("Dashboard/SET_DIALOG_LOADING", false, { root: true });
          Event.$emit("ApiSuccess", res.data.message);
          if (data.close) {
            dispatch("addIssue", true);
          } else dispatch("addIssue", false);
          Event.$emit("clearingForm");
          Event.$emit("refresh");
        })
        .catch((error) => {
          commit("Dashboard/SET_DIALOG_LOADING", false, { root: true });
          Event.$emit("ApiError", error.response.data.message);
        });
    },
    getAgents: ({ commit }) => {
      commit("Dashboard/SET_LOADING", true, { root: true });
      call("get", constants.AGENTS)
        .then((res) => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          commit("MUTATE", { state: "agents", data: res.data.data });
        })
        .catch((error) => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", error.response.data.message);
        });
    },
    selfUpdate: ({ commit, dispatch, state }, data) => {
      const selfLoading = { ...state.selfLoading };
      selfLoading[data.item] = true;
      commit("MUTATE", { state: "selfLoading", data: selfLoading });
      call("post", constants.patchIssue, data)
        .then(() => {
          Event.$emit("ApiSuccess", "Successfully Updated Issue");
          Event.$emit("clearEditor");
          dispatch("getIssue", data.issueCode);
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          selfLoading[data.item] = true;
          commit("MUTATE", { state: "selfLoading", data: selfLoading });
        });
    },
    selfGetIssues: ({ commit, state }) => {
      const selfLoading = { ...state.selfLoading };
      Object.keys(selfLoading).forEach((key) => {
        selfLoading[key] = false;
      });
      call("get", constants.ISSUES)
        .then((res) => {
          commit("MUTATE", { state: "selfLoading", data: selfLoading });
          commit("MUTATE", { state: "tickets", data: res.data.data });
        })
        .catch((error) => {
          commit("MUTATE", { state: "selfLoading", data: selfLoading });
          Event.$emit("ApiError", error.response.data.message);
        });
    },
    getfilteredCode({ commit }, payload) {
      commit("ADD_FILTERS", payload);
    },
    removeFilters({ commit }) {
      commit("REMOVE_FILTERS");
      Event.$emit("refresh");
    },
    getFilteredIssues({ commit }, payload) {
      commit("Dashboard/SET_LOADING", true, { root: true });
      call("post", constants.FILTER_ISSUES, payload)
        .then((res) => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          commit("MUTATE", { state: "issues", data: res.data.data });
        })
        .catch((error) => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", error.response.data.message);
        });
    },
    uploadExcel({ commit, dispatch }, payload) {
      commit("Dashboard/SET_LOADING", true, { root: true });
      call("post", constants.UPLOAD_EXCEL, payload)
        .then(() => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          dispatch("addIssue", false);
          Event.$emit("refresh");
          Event.$emit("ApiSuccess", "Excel file uploaded successfully");
        })
        .catch((error) => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", error.response.data.message);
        });
    },
    downloadExcelTemplate({ commit }) {
      commit("Dashboard/SET_LOADING", true, { root: true });
      call("get", constants.EXCEL_TEMPLATE)
        .then((res) => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          commit("MUTATE", {
            state: "excelTemplate",
            data: process.env.VUE_APP_TEMP_URL + res.data.data,
          });
        })
        .catch((error) => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", error.response.data.message);
        });
    },
    updateStatus({ commit, dispatch, state }, data) {
      const selfLoading = { ...state.selfLoading };
      selfLoading[data.item] = true;
      commit("MUTATE", { state: "selfLoading", data: selfLoading });
      call("post", constants.UPDATE_STATUS, data)
        .then((res) => {
          Event.$emit(
            "ApiSuccess",
            `Successfully marked Issue as ${res.data.data[0].status} `
          );
          dispatch("getIssue", res.data.data[0].issueCode);
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          selfLoading[data.item] = true;
          commit("MUTATE", { state: "selfLoading", data: selfLoading });
        });
    },
    getTicketLogs({ commit }, data) {
      commit("Dashboard/SET_DIALOG_LOADING", true, { root: true });
      call("get", constants.TICKET_LOGS(data))
        .then((res) => {
          commit("Dashboard/SET_DIALOG_LOADING", false, { root: true });
          commit("MUTATE", { state: "logs", data: res.data.data });
        })
        .catch((error) => {
          commit("Dashboard/SET_DIALOG_LOADING", false, { root: true });
          Event.$emit("ApiError", error.response.data.message);
        });
    },
    saveComment({ commit, dispatch }, data) {
      commit("Dashboard/SET_DIALOG_LOADING", true, { root: true });
      call("post", constants.SAVE_COMMENT, data)
        .then((res) => {
          Event.$emit("ApiSuccess", res.data.message);
          commit("Dashboard/SET_DIALOG_LOADING", false, { root: true });
          dispatch("getIssue", res.data.data[0].issue);
        })
        .catch((error) => {
          commit("Dashbaord/SET_DIALOG_LOADING", false, { root: true });
          Event.$emit("ApiError", error.response.data.message);
        });
    },
    editComment({ commit, dispatch }, data) {
      commit("Dashboard/SET_DIALOG_LOADING", true, { root: true });
      call("post", constants.EDIT_COMMENT, data)
        .then((res) => {
          Event.$emit("ApiSuccess", res.data.message);
          commit("Dashboard/SET_DIALOG_LOADING", false, { root: true });
          dispatch("getIssue", res.data.data[0].issue);
        })
        .catch((error) => {
          commit("Dashbaord/SET_DIALOG_LOADING", false, { root: true });
          Event.$emit("ApiError", error.response.data.message);
        });
    },
    addChildIssue({ commit, dispatch }, payload) {
      commit("Dashboard/SET_DIALOG_LOADING", true, { root: true });
      call("post", constants.ADD_CHILD_ISSUE, payload)
        .then(() => {
          Event.$emit(
            "ApiSuccess",
            "You have successfully added a child issue"
          );
          dispatch("getIssue", payload.parentIssue);
          commit("Dashboard/SET_DIALOG_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("Dashboard/SET_DIALOG_LOADING", false, { root: true });
        });
    },
    updateChildIssue({ commit, dispatch }, payload) {
      commit("Dashboard/SET_DIALOG_LOADING", true, { root: true });
      call("post", constants.EDIT_CHILD_ISSUE, payload)
        .then((res) => {
          Event.$emit("ApiSuccess", res.data.message);
          commit("Dashboard/SET_DIALOG_LOADING", false, { root: true });
          dispatch("getIssue", res.data.data[0].parentIssue);
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("Dashboard/SET_DIALOG_LOADING", false, { root: true });
        });
    },
    deleteChildIssue({ commit, dispatch }, payload) {
      commit("Dashboard/SET_DIALOG_LOADING", true, { root: true });
      call("post", constants.DELETE_CHILD_ISSUE, payload)
        .then((res) => {
          Event.$emit("ApiSuccess", res.data.message);
          commit("Dashboard/SET_DIALOG_LOADING", false, { root: true });
          dispatch("getIssue", payload.parentIssue);
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("Dashboard/SET_DIALOG_LOADING", false, { root: true });
        });
    },
    deleteAttachment({ commit, dispatch }, payload) {
      commit("Dashboard/SET_DIALOG_LOADING", true, { root: true });
      call("post", constants.DELETE_ATTACHMENT, payload)
        .then((res) => {
          Event.$emit("ApiSuccess", res.data.message);
          commit("Dashboard/SET_DIALOG_LOADING", false, { root: true });
          dispatch("getIssue", payload.no);
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("Dashboard/SET_DIALOG_LOADING", false, { root: true });
        });
    },
    addAttachment({ commit, dispatch }, payload) {
      commit("Dashboard/SET_DIALOG_LOADING", true, { root: true });
      call("post", constants.UPLOAD_ATTACHMENT, payload.data)
        .then((res) => {
          Event.$emit("ApiSuccess", res.data.message);
          Event.$emit("clearEditor");
          dispatch("getIssue", payload.code);
          commit("Dashboard/SET_DIALOG_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("Dashboard/SET_DIALOG_LOADING", false, { root: true });
        });
    },
  },
};
