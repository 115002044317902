import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"signup-request-white fill-height d-flex flex-row align-center",staticStyle:{"background-color":"rgba(255, 255, 255, 0.65)"},attrs:{"fluid":""}},[_c(VRow,[_c(VCol,{attrs:{"md":"4","sm":"12","offset-md":"4"}},[_c(VCard,{staticClass:"pa-5"},[_c('div',{staticClass:"text-center"},[_c('Logo',{staticClass:"my-5",attrs:{"height":"150"}})],1),_c(VCardText,{staticClass:"text-justify mt-4 pb-0"},[_c(VForm,{ref:"resetPasswordForm",on:{"submit":function($event){$event.preventDefault();return _vm.reset.apply(null, arguments)}}},[_c(VAlert,{attrs:{"type":"info","text":""}},[_vm._v(" You're Almost there, Create a new Password to login ")]),_c(VTextField,{attrs:{"dense":"","required":"","outlined":"","type":_vm.passwordShow ? 'text' : 'password',"label":"New Password","placeholder":"Enter a secure password","rules":_vm.rules.password,"append-icon":_vm.passwordShow ? 'mdi-eye' : 'mdi-eye-off'},on:{"copy":function($event){$event.preventDefault();},"paste":function($event){$event.preventDefault();},"click:append":function($event){_vm.passwordShow = !_vm.passwordShow}},scopedSlots:_vm._u([{key:"message",fn:function(ref){
var message = ref.message;
return [_c('ul',{staticClass:"my-1"},_vm._l((_vm.formatMessage(message)),function(item,i){return _c('li',{key:i,staticClass:"my-1"},[_vm._v(" "+_vm._s(item)+" ")])}),0)]}}]),model:{value:(_vm.formData.password),callback:function ($$v) {_vm.$set(_vm.formData, "password", $$v)},expression:"formData.password"}}),_c(VTextField,{attrs:{"dense":"","required":"","outlined":"","type":_vm.showPassword ? 'text' : 'password',"append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',"label":"Confirm Password","placeholder":"Enter Password again","rules":_vm.rules.confirmPassword},on:{"copy":function($event){$event.preventDefault();},"paste":function($event){$event.preventDefault();},"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.formData.confirmPassword),callback:function ($$v) {_vm.$set(_vm.formData, "confirmPassword", $$v)},expression:"formData.confirmPassword"}})],1)],1),_c(VCardActions,{},[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[(!_vm.submitting)?_c(VBtn,{attrs:{"color":"primary","block":""},on:{"click":_vm.reset}},[_vm._v(" Submit ")]):_vm._e(),(_vm.submitting)?_c(VBtn,{attrs:{"color":"primary","block":""}},[_c('i',{staticClass:"fa fa-refresh fa-spin loading-button-spinner"}),_vm._v(" Processing...Please Wait ")]):_vm._e()],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }