import DashboardLayout from "@/packages/dashboard/modules/dashboard/views/dashboardLayout";
import Auth from "@/packages/dashboard/modules/auth/middleware/Auth";
import Issues from "./views/Issues";
import IssuesCard from "./components/IssuesCard.vue";

export default [
  {
    path: "/tickets",
    component: DashboardLayout,
    children: [
      {
        path: "",
        redirect: "/tickets/issues",
      },
      {
        path: "issues",
        name: "issues",
        component: Issues,
        children: [
          {
            path: "",
            redirect: "/tickets/issues/list",
          },
          {
            path: "issue",
            name: "IssuesCard",
            component: IssuesCard,
            meta: { middleware: [Auth] },
          },
        ],
      },
    ],
  },
];
