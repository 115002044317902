import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"signup-request-white auth fill-height d-flex flex-row align-center",attrs:{"fluid":""}},[_c(VRow,[_c(VCol,{attrs:{"md":"4","sm":"12","offset-md":"4"}},[_c(VCard,{staticClass:"pa-5"},[_c('Logo',{staticClass:"my-5",attrs:{"height":"150"}}),_c(VCardText,{staticClass:"text-justify mt-4 pb-0"},[(_vm.$store.getters['Auth/alert'].status)?_c(VAlert,{staticClass:"mb-10",attrs:{"outlined":"","dense":"","border":"left","type":_vm.$store.getters['Auth/alert'].status === 'success'
                ? 'success'
                : 'error'}},[_vm._v(" "+_vm._s(_vm.$store.getters["Auth/alert"].message)+" ")]):_vm._e(),_c(VTextField,{ref:"email",attrs:{"dense":"","outlined":"","type":"email","label":"Email Address","placeholder":"Enter your Email Address","rules":_vm.rules.email},on:{"copy":function($event){$event.preventDefault();},"paste":function($event){$event.preventDefault();}},model:{value:(_vm.formData.email),callback:function ($$v) {_vm.$set(_vm.formData, "email", $$v)},expression:"formData.email"}})],1),_c(VCardActions,{},[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('span',{},[_vm._v("Already have an account?")]),_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{ name: 'SignIn' }}},[_vm._v(" Sign In ")])],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VBtn,{attrs:{"color":"primary","block":""},on:{"click":_vm.reset}},[_vm._v(" Send Login Link ")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }