import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,[_vm._l((_vm.attachments),function(attachment,i){return _c(VCol,{key:i,attrs:{"cols":"12","md":"4","sm":"3"}},[_c(VSheet,{staticClass:"d-flex",attrs:{"color":_vm.fileMeta(attachment.attachment).color,"dark":"","outlined":"","rounded":""}},[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"9"}},[_c(VListItem,{staticClass:"pr-0",attrs:{"link":""},on:{"click":function($event){return _vm.viewDoc(attachment)}}},[_c(VListItemIcon,{staticClass:"mr-0"},[_c(VIcon,[_vm._v(" "+_vm._s(_vm.fileMeta(attachment.attachment).icon)+" ")])],1),_c(VListItemContent,[_c(VListItemTitle,[_c('span',{domProps:{"textContent":_vm._s(
                    attachment.fileName.length > 10
                      ? attachment.fileName.slice(0, 10)
                      : attachment.fileName
                  )}})])],1)],1)],1),_c(VCol,{staticClass:"d-flex justify-start align-center",attrs:{"cols":"3"}},[_c(VListItem,[_c(VListItemIcon,[_c(VIcon,{on:{"click":function($event){return _vm.deleteAttachment(attachment)}}},[_vm._v(" mdi-delete ")])],1)],1)],1)],1)],1)],1)}),_c(VDialog,{attrs:{"persistent":"","width":_vm.$vuetify.breakpoint.mdAndUp ? '60%' : '100%',"fullscreen":_vm.$vuetify.breakpoint.mobile},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,[_c(VSpacer),_c(VBtn,{staticClass:"ml-3",attrs:{"color":"primary","label":"","small":"","dense":"","dark":""},on:{"click":function($event){return _vm.download(_vm.document.download)}}},[_c(VIcon,[_vm._v("mdi-download-circle")]),_vm._v("Download ")],1)],1),_c(VDivider),(_vm.show === true)?_c('div',[(_vm.document.fileExtension === 'pdf')?_c(VCardText,{staticClass:"overflow-y-auto overflow-x-hidden mt-3",staticStyle:{"max-height":"70vh"}},_vm._l((_vm.document.pages),function(i){return _c(VCardText,{key:i,staticClass:"elevation-2 mb-2"},[_c('pdf',{staticStyle:{"width":"100%"},attrs:{"src":("" + _vm.url + (_vm.document.attachment)),"page":i}})],1)}),1):_c(VCardText,{staticClass:"overflow-y-auto overflow-x-hidden mt-3",staticStyle:{"max-height":"70vh"}},[_c(VImg,{staticClass:"pa-9",attrs:{"src":_vm.url + _vm.document.attachment}})],1)],1):_c(VCardText,{staticClass:"overflow-y-auto overflow-x-hidden mt-3",staticStyle:{"max-height":"70vh"}},[_c(VSkeletonLoader,{attrs:{"type":"image"}})],1),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"dark":"","depressed":"","color":"error","plain":""},on:{"click":function($event){(_vm.dialog = false), (_vm.show = false)}}},[_vm._v(" close ")])],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }