import DashboardLayout from "@/packages/dashboard/modules/dashboard/views/dashboardLayout";
import Profile from "./views/Profile";
import ProfileCard from "./components/ProfileCard";
import Auth from "@/packages/dashboard/modules/auth/middleware/Auth";

export default [
  {
    path: "/profile",
    component: DashboardLayout,
    children: [
      {
        path: "card",
        name: "Profile",
        components: {
          view: Profile,
        },
        children: [
          {
            path: "",
            redirect: "/profile/card/profileSettings",
          },
          {
            path: "profileSettings",
            name: "ProfileCard",
            component: ProfileCard,
            meta: { middleware: [Auth] },
          },
        ],
        meta: { middleware: [Auth] },
      },
    ],
  },
];
