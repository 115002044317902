<template>
  <div>
    <v-avatar size="100">
      <v-img
        v-if="company.base64Logo"
        alt="Logo"
        :src="`data:image/png;base64,${company.base64Logo}`"
        @click="$router.push({ name: 'Summary' })"
      />
      <v-img v-else :height="height" src="../assets/logo.png" contain />
    </v-avatar>
  </div>
</template>

<script>
export default {
  name: "Logo",
  props: {
    height: {
      type: [Number, String],
      default: () => 50,
    },
    rounded: {},
  },
  computed: {
    company() {
      return this.$store.getters["Landing/company"];
    },
  },
};
</script>

<style scoped></style>
