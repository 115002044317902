import call from "../../service/http";
import { AuthConstants } from "./index";
import AuthService from "./AuthService";

export default {
  namespaced: true,
  state: {
    terms: {},
    requirements: [],
    verified: "pending",
    alert: {
      status: "",
      message: "",
    },
  },
  mutations: {
    MUTATE() {},
    SET_VERIFY: (state, payload) => {
      state.verified = payload;
    },
    SET_ALERT: (state, payload) => {
      state.alert = payload || {
        status: "",
        message: "",
      };
    },
  },
  getters: {
    error: (state) => state.error,
    verified: (state) => state.verified,
    alert: (state) => state.alert,
  },
  actions: {
    // application registration
    applicationRegistration: ({ commit }, data) => {
      commit("Dashboard/SET_LOADING", true, { root: true });
      call("post", AuthConstants.APPLICATION_REGISTRATION, data)
        .then((res) => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          if (res.data.data.user) {
            Event.$emit("signup-verify", res.data.data);
          }
        })
        .catch((err) => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", err.response.data.message);
        });
    },

    sendActivationLink: ({ commit }, data) => {
      commit("Dashboard/SET_LOADING", false, { root: true });
      call("post", AuthConstants.REQUEST_CREDENTIALS("client"), data)
        .then((res) => {
          res.data === null
            ? Event.$emit(
                "ApiWarning",
                `${res.data.message}! Please try again later`
              )
            : Event.$emit(
                "ApiSuccess",
                "Your credentials will be sent to your email shortly!"
              );
          commit("Dashboard/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", `${error.response.data.message}`);
          commit("Dashboard/SET_LOADING", false, { root: true });
        });
    },

    login: ({ commit }, data) => {
      localStorage.clear(); // clear everything in this domain
      call("post", AuthConstants.login, data)
        .then((res) => {
          Event.$emit("loggedIn", true);
          if (!res.data.data.user.is_verified) {
            Event.$emit("signup-verify", res.data.data);
            Event.$emit("ApiWarning", "Verify Your Account To Login");
          } else {
            // if (!res.data.data.data.success) {
            //   // device not recognised
            //   Event.$emit("login-verify", res.data.data);
            // } else {
            // console.log(res)
            AuthService.login(res.data.data.token, res.data.data.user);
            Event.$emit("ApiSuccess", "Successfully Logged in");
            // }
            commit("Dashboard/SET_LOADING", false, { root: true });
          }
        })
        .catch((err) => {
          Event.$emit("loggedIn", false);
          commit("Dashboard/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", err.response.data.message);
        });
    },

    setCompany: ({ commit }, data) => {
      commit("Dashboard/SET_LOADING", true, { root: true });
      call("post", AuthConstants.setCompany, data)
        .then((res) => {
          AuthService.setUser(res.data.data);
          Event.$emit("ApiSuccess", "Company Successfully Changed");
          commit("Dashboard/SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", err.response.data.message);
        });
    },

    verify: ({ commit }, data) => {
      commit("Dashboard/SET_LOADING", true, { root: true });
      call("post", AuthConstants.VERIFY_ACCOUNT, data)
        .then(() => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          commit("SET_VERIFY", "success");
        })
        .catch((err) => {
          commit("SET_VERIFY", "failed");
          commit("Dashboard/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", err.response.data.message);
        });
    },

    forgotPassword: ({ commit }, data) => {
      commit("Dashboard/SET_LOADING", true, { root: true });
      call("post", AuthConstants.passwordReset, data)
        .then((res) => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          Event.$emit("ApiSuccess", res.data.data);
        })
        .catch((err) => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", err.response.data.message);
        });
    },

    resetPassword: ({ commit }, data) => {
      commit("Dashboard/SET_LOADING", true, { root: true });
      call("post", AuthConstants.password, data)
        .then((res) => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          Event.$emit("ApiSuccess", "success");
          setTimeout(() => {
            AuthService.login(res.data.data.token, res.data.data.user);
          }, 2000);
        })
        .catch((err) => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", err.response.data.message);
        });
    },

    changePassword: ({ commit }, data) => {
      commit("Dashboard/SET_LOADING", true, { root: true });
      call("post", AuthConstants.CHANGE_PASSWORD, data)
        .then(() => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          Event.$emit("ApiSuccess", "Success");
          setTimeout(() => {
            AuthService.logout();
          }, 3000);
        })
        .catch((err) => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", err.response.data.message);
          setTimeout(() => {
            Event.$emit(
              "ApiInfo",
              "TIP: Is current password matches the previous password?"
            );
          }, 5000);
        });
    },

    user: ({ commit }) => {
      commit("SET_ALERT", null);
      commit("Dashboard/SET_LOADING", true, { root: true });
      call("get", AuthConstants.user).then((res) => {
        AuthService.setUser(res.data.data);
        commit("Dashboard/SET_LOADING", false, { root: true });
      });
    },

    logout: () => {
      call("get", AuthConstants.logout).then(() => {
        AuthService.logout();
      });
    },
  },
};
