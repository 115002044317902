<template>
  <v-overlay
    v-if="$store.getters['Dashboard/dialogLoading']"
    z-index="10"
    color="drawerBackground"
    opacity=".3"
  >
    <div class="d-flex flex-column">
      <v-progress-circular
        indeterminate
        size="40"
        width="2"
        color="loaderColor"
        class="align-self-center mt-3"
      ></v-progress-circular>
    </div>
  </v-overlay>
</template>

<script>
export default {
  name: "Spinner",
  components: {},
};
</script>

<style scoped></style>
