<template>
  <v-container class="background" height="100vh" fluid>
    <AppBarTop />
    <v-card class="mx-auto mt-md-16" width="900px">
      <v-card-title class="ml-3">
        <span class="text-h4 mb-n2"> Create Issue </span>
      </v-card-title>
      <v-card-text v-if="!authenticated">
        <v-skeleton-loader
          v-bind="attrs"
          type="card-heading, list-item, list-item, image, list-item, image, actions"
        />
      </v-card-text>
      <!-- <IssuesCard :isEdit="isEdit" :padding="padding" v-else /> -->
    </v-card>
    <UpdateProfile :dialog="dialog" />
    <Spinner />
  </v-container>
</template>
<script>
// import IssuesCard from "@/packages/tickets/modules/issues/components/IssuesCard";
import AppBarTop from "@/packages/dashboard/modules/dashboard/components/Bars/AppBarTop";
import Spinner from "@/packages/dashboard/plugins/loader/views/Spinner";
import AuthService from "@/packages/dashboard/modules/auth/AuthService";
import UpdateProfile from "./UpdateProfile.vue";
export default {
  name: "LandingIssueCard",
  components: { AppBarTop, Spinner, UpdateProfile },
  data: function () {
    return {
      isEdit: false,
      padding: 3,
      dialog: false,
      attrs: {
        class: "my-5",
        boilerplate: false,
        elevation: 0,
      },
    };
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      if (AuthService.check()) {
        v.$router.push({ name: "Main" });
      }
      v.dialog = true;
      v.$store.dispatch("Setup/getProjects");
    });
  },
  computed: {
    project() {
      const projects = this.$store.getters["Setup/setupGetters"]("projects");
      return projects
        .filter((item) => {
          return item.code === this.$route.params.company;
        })
        .shift();
    },
    authenticated() {
      return AuthService.check();
    },
  },
};
</script>
<style scoped>
@import url("../styles.css");
</style>
