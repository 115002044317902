export default {
  ISSUES: (params) => `tickets?${getParams(params)}`,
  ADD_ISSUE: "tickets/addIssue",
  AGENTS: "users/assignees",
  patchIssue: `tickets/update`,
  // ASSIGN_ISSUE: "tickets/assignIssue",
  EXCEL_TEMPLATE: "tickets/excel/template",
  UPDATE_STATUS: "tickets/updateStatus",
  TICKET_LOGS: (id) => `tickets/logs/${id}`,
  SAVE_COMMENT: "tickets/issueComments",
  EDIT_COMMENT: "tickets/issueComments/update",
  ISSUE: (id) => `tickets/${id}`,
  ADD_CHILD_ISSUE: "tickets/childIssues",
  EDIT_CHILD_ISSUE: "tickets/childIssues/update",
  DELETE_CHILD_ISSUE: "tickets/childIssues/delete",
  UPLOAD_ATTACHMENT: "tickets/attachments",
  DELETE_ATTACHMENT: "tickets/attachments/delete",
  FILTER_ISSUES: "tickets/filter",
};

function getParams(params) {
  return Object.keys(params)
    .map((key) => {
      return `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`;
    })
    .join("&");
}
