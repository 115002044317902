<template>
  <v-dialog
    v-model="dialogBox"
    persistent
    max-width="50%"
    :fullscreen="$vuetify.breakpoint.mobile"
  >
    <v-card class="px-4">
      <v-card-title>
        User Invitation

        <v-spacer></v-spacer>

        <v-btn icon @click="dialogBox = false">
          <v-icon color="error">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text
        class="overflow-y-auto overflow-x-hidden mt-3"
        style="max-height: 70vh"
      >
        <v-form v-model="isValid" ref="invitationForm">
          <v-row>
            <v-col cols="12">
              <span>Email <span class="red--text">*</span></span>
              <v-text-field
                filled
                single-line
                densed
                label="Email"
                :rules="rules.email"
                v-model="formData.email"
              />
            </v-col>

            <v-col cols="6" class="mt-n8">
              <span>First Name <span class="red--text">*</span></span>
              <v-text-field
                filled
                single-line
                densed
                label="First Name"
                :rules="rules.required"
                v-model="formData.fName"
              />
            </v-col>

            <v-col cols="6" class="mt-n8">
              <span>Last Name <span class="red--text">*</span></span>
              <v-text-field
                filled
                single-line
                densed
                label="Last Name"
                :rules="rules.required"
                v-model="formData.lName"
              />
            </v-col>

            <v-col cols="12" class="mt-n8">
              <span>Phone Number <span class="red--text">*</span></span>
              <v-text-field
                filled
                single-line
                densed
                label="Phone Number"
                :rules="rules.phoneNo"
                v-model="formData.phoneNo"
              />
            </v-col>

            <v-col cols="12" class="mt-n8">
              <span>User Type<span class="red--text">*</span></span>
              <v-autocomplete
                filled
                single-line
                densed
                label="User Type"
                :items="user_type"
                :rules="rules.required"
                v-model="formData.user_type"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="sendInvitation">Send</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "UsersCard",
  props: { dialog: Boolean, profile: Object },
  data() {
    return {
      formData: {
        email: "",
        company: "",
        user_code: "",
        fName: "",
        lName: "",
        phoneNo: "",
      },
      user_type: ["Client", "Client_Admin"],
      isValid: false,
    };
  },
  computed: {
    dialogBox: {
      get() {
        return this.dialog;
      },
      set(val) {
        this.$emit("closeDialogEvent", val);
      },
    },
    rules() {
      return {
        required: [(v) => !!v || "field is required"],
        email: [
          (v) => !!v || "E-mail is required",
          (v) => /.+@.+/.test(v) || "E-mail must be valid",
        ],
        phoneNo: [
          (v) => !!v || "Phone Number is required",
          (v) =>
            !v ||
            /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(v) ||
            "Invalid phone number format",
        ],
      };
    },
  },
  methods: {
    sendInvitation() {
      if (!this.isValid) {
        this.$refs.invitationForm.validate();
      } else {
        this.formData.company = this.profile.company;
        this.formData.user_code = (
          this.formData.fName.charAt(0) + this.formData.lName.charAt(0)
        ).toUpperCase();
        this.formData.name = this.formData.fName + this.formData.lName;
        this.$store.dispatch("UserManagement/sendInvitation", {
          ...this.formData,
        });
        this.dialogBox = false;
      }
    },
  },
};
</script>
