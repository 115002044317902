<template>
  <v-container>
    <v-sheet>
      <router-view />
    </v-sheet>
  </v-container>
</template>

<script>
export default {
  name: "Profile",
};
</script>
